import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner';
import SftGlobalData from '../../library/SftGlobalData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';
import { DataGridPro, GridActionsCellItem  } from '@mui/x-data-grid-pro';
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, Switch, Stack, ListItemText, TextField } from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import CryptoJS from 'crypto-js';

import 'react-toastify/dist/ReactToastify.css';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
    };

const LicenceAPIForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [accountId, setAccountId] = useState("0");
    const [APIUsers, setAPIUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState("1001");
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const [qrToken, setQrToken] = useState("");
    const [hasQr, setHasQR] = useState(false);
    const [createQrToken, setCreateQrToken] = useState(false);
    const [stores, setStores] = useState([]);
    const [pos, setPos] = useState([]);

    const [gateStore, setGateStore] = useState("");
    const [gatePos, setGatePos] = useState("");
    const [gateUser, setGateUser] = useState("");
    const [gateUsers, setGateUsers] = useState([]);

    const [selectedUser, setSelectedUser] = useState("");
    const [modalOpen, setModalOpen] = useState(false);


    useEffect (()=>{
        loadUsers();
        fetchApiUsers();
    }, [props.api]);

    useEffect (()=>{
        try{
            if(props?.apiData?.length > 0){
                setAPIUsers(props.apiData);
            }
        } catch(e){
            console.error(e);
        }
    }, [props.apiData]);

    function decryptData(encryptedString, key) {
        // Decode the Base64 string
        const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedString);
    
        // Convert the key to WordArray format (required by CryptoJS)
        const keyWordArray = CryptoJS.enc.Utf8.parse(key);
    
        // Decrypt using AES with ECB mode and no padding
        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encryptedBytes },
            keyWordArray,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.NoPadding
            }
        );
    
        // Convert the decrypted WordArray back to a UTF-8 string
        let plaintext = decrypted.toString(CryptoJS.enc.Utf8);

        // Manually remove any padding characters (like \u000E)
        plaintext = plaintext.replace(/\u000E+$/, '');
        
        //remove other non whitespace characters
        plaintext = plaintext.replace(/\s/g, '');
    
        // Validate the decrypted string
        if (!plaintext) {
            throw new Error("Invalid checksum.");
        }    

        plaintext = plaintext.substring(0, plaintext.lastIndexOf('}')+1);
        
        plaintext = JSON.parse(plaintext);

        return plaintext;
    }

    useEffect(() => {
       if(!hasQr){
           setCreateQrToken(true);
       }else{
           setCreateQrToken(false);
       }
    }, [hasQr]);

    const loadUsers = () => {
        let url = SftGlobalData.baseURL_API + 'sftData';
        let postData = { type: 'allUsers', key: props.api };
        axios.post(url, postData, 
            {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('data', postData)
                    console.log('response loadUsers', response);
                }
                //append softtouch users to gate users as first user
                response.data?.data?.unshift({userid: 1001, naam: 'SoftTouch', 'active': 'T'});
                setGateUsers(response.data.data);
            }
        );
    }

    const fetchApiUsers = async () => {
        if(!qrToken){
            var reqUID = Math.floor((Math.random() * 10000) + 1);
            let fetchQrTokenUrl = SftGlobalData.baseURL_office + 'qr/request.php?&api=get&qr=token&db_id='+ props.dbId +'&client_id='+props.clientId+'&req='+reqUID;

            //fetch the token that is used for the qr code
            let response = await fetch(fetchQrTokenUrl);
            let json = await response.json();
            if(SftGlobalData.debug){
                console.log('fetchQrTokenUrl', fetchQrTokenUrl);
                console.log('json', json);
            }

            setHasQR(json?.has_connection);
            (json?.stores?.code == 500) ? setStores([]) : setStores(json?.stores);
            (json?.pos?.code == 500) ? setPos([]) : setPos(json?.pos);

            let  gateInfo = json?.gate?.gate;
            setGateUser(gateInfo?.user);
            setGateStore(gateInfo?.store);
            setGatePos(gateInfo?.pos);

            let encryptedBaseData = json.baseData;
            let key = "EHOrsWQ1mnWzwc09L9xGNBLn";

            if(json?.has_connection){
                try  {
                    const decrypted = decryptData(encryptedBaseData, key);
                    setQrToken(decrypted.token);
                } catch (error) {
                    console.error(error.message);
                }
            }
        }

        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchAPI';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp fetchApi', response);
                    }

                    setAccountId(response.data.accountId);
                    setAPIUsers(response.data.users);
                    url = SftGlobalData.baseURL_API + 'sftData';
                        axios.post(url, 
                            {
                                type: 'users',
                                userid: "1001",
                                key: props.api
                            }, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }
                            }).then(response => {
                                if(SftGlobalData.debug){
                                    console.log('url', url);
                                    console.log('data', { type: 'users', userid: "1001", key: props.api });
                                    console.log('response', response);
                                }
                                setUsers(response.data.data);
                                setLoading(false);
                                setTableLoading(false);
                            }
                        );
                });
        } catch(e){
            console.error(e);
        }
    }

    const usersMapper = users?.map(user => {
        return (
            <MenuItem key={user.userid} value={user.userid}>{user.userid} - {user.naam}</MenuItem>
        );
    });

    const gridWebhookColumns = [
        {
            field: 'avatar',
            headerName: '',
            width: 45,
            maxWidth: 45,
            minWidth: 45,
            align: 'center',
            display: 'flex',
            renderCell: (params) => {
                if(params.row.avatar !== null)
                    return <img alt="Avatar" style={{scale: 'fitXY', width: 38, height: 38, borderWidth: 0}} src={params.row.avatar} />
                else
                    return <div></div>
            }
        },
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 60,
            maxWidth: 60,
            width: 60,
            align: "center",
            display: 'flex',
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            width: 230,
            display: 'flex',
        },
        {
            field: 'fullname',
            headerName: 'Name',
            minWidth: 200,
            display: 'flex',
        },
        {
            field: 'is_admin',
            headerName: 'Admin?',
            minWidth: 75,
            align: 'center',
            display: 'flex',
            renderCell: (params) => {
                if(params.row.is_admin){
                    return <FontAwesomeIcon size={"2x"} icon={faCheck} color={Colors.def_green} />
                } else {
                    return <FontAwesomeIcon size={"2x"} icon={faTimes} color={Colors.def_red} />
                }
            }
        },
        {
            field: 'is_qr_token',
            headerName: 'Is QR Token?',
            minWidth: 75,
            align: 'center',
            display: 'flex',
            renderCell: (params) => {
                if(params.row.is_qr_token){
                    return <FontAwesomeIcon size={"2x"} icon={faCheck} color={Colors.def_green} />
                } else {
                    return <FontAwesomeIcon size={"2x"} icon={faTimes} color={Colors.def_red} />
                }
            }
        },
        {
            field: 'actions',
            type: 'actions',
            width: 40,
            maxWidth: 40,
            minWidth: 40,
            display: 'flex',
            getActions: (params) => [
                props.disabled ? <div></div> : <GridActionsCellItem
                icon={<DeleteIcon  sx={{ color: Colors.def_red }} />}
                label="Remove"
                onClick={()=>{removeUser(params.row.email);}}
              />
            ],
        },
      ];
    
    const removeUser = async (email) => {
        setSelectedUser(email);
        setModalOpen(true);
    }

    const onRemoveUser = async () => {
        let url = SftGlobalData.baseURL_API + 'client&action=removeApiUser';
        axios.post(url, {
            key: props.api,
            email: selectedUser,
            id: accountId
        }, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            try{
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                setModalOpen(false);
                setTableLoading(true);
                fetchApiUsers();
            } catch(e){
                props.notify(response.data.message, 7500, "top-right", props.theme, 1); 
            }

        });
    }

    const onAdd = async (params = {}) => {
        const firstNameToUse = params.firstName || firstName;
        const nameToUse = params.name || name;
        const emailToUse = params.email || email;
        const passwordToUse = params.password || password;
        const createQrTokenToUse = params.createQrToken !== undefined ? params.createQrToken : createQrToken;

        if(firstNameToUse.length > 2 && nameToUse.length > 2 && emailToUse.length > 2) {
            let url = SftGlobalData.baseURL_API + 'client&action=addApiUser';
            axios.post(url, {
                key: props.api,
                first_name: firstNameToUse,
                last_name: nameToUse,
                email: emailToUse,
                password: passwordToUse,
                createQr: createQrTokenToUse
            }, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                try{
                    if(response.data.id){
                        props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                        let tempUsers = APIUsers;
                        tempUsers.push(response.data);
                        if(SftGlobalData.debug){
                            console.log('tempUsers', tempUsers);
                        }
                        setAPIUsers(tempUsers);
                        setFirstName("");
                        setName("");
                        setEmail("");
                        setPassword("");
                        (createQrToken) ? setCreateQrToken(false) : (hasQr) ? setCreateQrToken(false) : setCreateQrToken(true);
                        props.setMemo(response.data.memo);
                        loadUsers();
                        fetchApiUsers();  
                    }
                } catch(e){
                    props.notify(response.data.message, 7500, "top-right", props.theme, 1); 
                }

            });
        } else {
            props.notify("ERROR: API fields need more than 2 characters.", 7500, "top-right", props.theme, 1); 
        }
    }

    const onCreateAccount = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=createApiAccount';
            let postData = {key: props.api, user_id: selectedUserId, createQrToken: createQrToken};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setAccountId(response.data.accountId);
                    onAdd({
                        firstName: props.dbDescr,
                        name: "SoftTouch",
                        email: `${props.dbDescr}@sft.be`,
                        password: "AEldI23!dsDwe56",
                        createQrToken: createQrToken
                    });
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    const updateGateInfo = async () => {
        try{ 
            let url = SftGlobalData.baseURL_API+'client&action=updateGateInfo';
            let postData = {key: props.api, gate_user: gateUser, gate_store: gateStore, gate_pos: gatePos};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1);
                });
        } catch(e){
            console.error(e);
        }
    }

    function DataGridAPI() {
        return (
          <div className={props.theme + " _70perc"}>
            {accountId !== "0" ? 
            <p style={{marginLeft: 20}}>
                <b>API Account ID: </b>
                 {accountId}
            </p>
            :
            (<div>
                <br/><br/>
                <FormControl style={{width: '49%'}}>
                    <InputLabel id="apl" htmlFor="aplselect">API gate user ID</InputLabel>
                    <Select labelId="apl" id="aplselect" value={selectedUserId} onChange={(event)=>{setSelectedUserId(event.target.value)}} label="API default user ID" >
                        <MenuItem value="1001"><em>1001 - _SOFTTOUCH</em></MenuItem>
                        {usersMapper}
                    </Select>
                </FormControl>
                <br/><br/>
                <Button onClick={()=>{onCreateAccount();}}>Create API account ID</Button>
            </div>)
            }
            {APIUsers !== null && accountId !== "0" && qrToken && <DataGridPro
                className={props.theme}
                columns={gridWebhookColumns}

                rows={APIUsers.map((row, index) => {return {
                        id: row.id,
                        email: row.email,
                        fullname: row.fullname,
                        firstname: row.firstname,
                        lastname: row.lastname,
                        is_admin: row.is_admin,
                        is_qr_token: row.tokens?.find(token => token.access_token === qrToken) ? true : false,
                        avatar: row.avatar,
                        actions: ""
                    }
                })}
                //{...data}
                rowHeight={38}
                style={{height: 525}}
                pagination={false}
                loading={loading || tableLoading}
                components={{
                    LoadingOverlay: LinearProgress,
                }}

                />   }
          </div>
        );
    }


    return (
        <div className={props.theme} style={{width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={()=>{setModalOpen(false)}}
                BackdropComponent={Backdrop}
                >
                <Box sx={style} style={{minWidth: 400}} className={props.theme}>
                    <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>Remove user?</p>
                    <div>
                        <p>Are you sure you wish to <b>remove “{selectedUser}”</b> from the API account?</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onRemoveUser()}}>{props.t('yes')}</Button>
                        <Button className={"modal_button_container_no_margin__button margin_top_5px _100perc"} onClick={()=>{setModalOpen(false); setSelectedUser("");}}>{props.t('no')}</Button>
                    </div>
                </Box>
            </StyledModal>

            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            
            {!loading && DataGridAPI()}

            {(!loading && accountId !== "0" && !props.disabled) && <div className={props.theme + " _25perc margin_left__15px"}>
           
            <Stack direction="column" spacing={1}>

                {stores ? <>
                <b style={{marginTop: 10}}>Gate user settings</b>
                <FormControl style={{ width: '100%'}}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('store')}:</InputLabel>
                    <Select id="gate_store"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('store')} 
                    value={gateStore} 
                    onChange={(e) => {setGateStore(e.target.value)}} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => {
                        if (!selected)
                            return " - ";
                        const selectedOption = stores?.find(option => option.key === selected);
                        return selectedOption ? (selectedOption.key + ': ' + selectedOption.txt) : '';
                    }}>
                        {stores?.filter((row) => row.active === 'T').map((row, index) => {
                            return (
                                <MenuItem value={row.key} name={row.txt} key={index}>
                                    <ListItemText
                                        primary={<span>{row.key}: {row.txt}</span>}
                                    />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl> </> : ''}

                {pos ? <FormControl style={{ width: '100%'}}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('pos')}:</InputLabel>
                    <Select id="gate_pos"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('pos')} 
                    value={gatePos} 
                    onChange={(e) => {setGatePos(e.target.value)}} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => {
                        if (!selected)
                            return " - ";
                        const selectedOption = pos?.find(option => option.key === selected);
                        return selectedOption ? (selectedOption.key + ': ' + selectedOption.txt) : '';
                    }}>
                        {pos?.filter((row) => row.active).map((row, index) => {
                            return (
                                <MenuItem value={row.key} name={row.txt} key={index}>
                                    <ListItemText
                                        primary={<span>{row.key}: {row.txt}</span>}
                                    />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl> : ''}

                {gateUsers && gateUser ? <FormControl style={{ width: '100%'}}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('user')}:</InputLabel>
                    <Select id="gate_user"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('user')} 
                    value={gateUser} 
                    onChange={(e) => {setGateUser(e.target.value)}} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => {
                        if (!selected)
                            return " - ";
                        const selectedOption = gateUsers?.find(user => user.userid == selected);
                        return selectedOption ? (selectedOption.naam + ' ' + selectedOption.userid) : '';
                    }}>
                        {gateUsers?.filter((user) => user?.active == 'T').map((user, index) => {
                            return (
                                <MenuItem value={user.userid} name={user.naam} key={user.userid}>
                                    <ListItemText
                                        primary={<span>{user.naam} {user.userid}</span>}
                                    />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl> : ''}

                { stores && pos && gateUser && gateUsers ? 
                    <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{updateGateInfo();}}>{props.t('edit')}</Button> : ''
                }

                </Stack>

            <Stack direction="column" spacing={1}>
                <p>
                    <b>Add user</b>
                    <span style={{float: 'right'}}>
                        {hasQr ? props.t('overwrite_qr_code') : props.t('create_qr_code')}
                        <Switch
                        checked={createQrToken}
                        onChange={()=>{setCreateQrToken(!createQrToken);}}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </span>
  
                </p>
                <TextField 
                    autoComplete="off" 
                    className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                    id="name"
                    label={props.t('name')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={name}
                    onChange={(event)=>{setName(event.target.value)}}
                    />
                <TextField 
                    autoComplete="off" 
                    className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                    id="firstName"
                    label={props.t('firstname')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={firstName}
                    onChange={(event)=>{setFirstName(event.target.value)}}
                    />
                <TextField 
                    autoComplete="off" 
                    type='email'
                    className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                    id="email"
                    label={props.t('email')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={email}
                    onChange={(event)=>{setEmail(event.target.value)}}
                    />
                <TextField 
                    autoComplete="off" 
                    type='password'
                    className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                    id="password"
                    label={props.t('password')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={password}
                    onChange={(event)=>{setPassword(event.target.value)}}
                    />

                <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onAdd();}}>{props.t('add')}</Button>
                </Stack>
            </div>}
        </div>
    );
}

export default LicenceAPIForm;
