import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Routes,
  Route,
  useParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import SftGlobalData from './library/SftGlobalData';

import './App.css';
import './styles/christmas.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ErrorBoundary from './ErrorBoundary';
import DevScreen from './screens/DevScreen';
import LoginScreen from './screens/login/LoginScreen';
import EnvironmentScreen from './screens/environment/EnvironmentScreen';
import HomeScreen from './screens/HomeScreen';
import Header from './components/Header';
import Footer from './components/Footer';
import AuthScreen from './screens/AuthScreen';
import IntegrationScreen from './screens/IntegrationScreen';
import IntegrationDetailScreen from './screens/IntegrationDetailScreen';
import LeftSidebar from './components/sidebar/LeftSidebar';
import MailchimpScreen from './screens/MailchimpScreen';
import MailIntegrationsScreen from './screens/mailintegrations/MailIntegrationsScreen';
import MailIntegrationsSetup from './screens/mailintegrations/MailIntegrationsSetup';
import BirthdayScreen from './screens/BirthdayScreen';
import BirthdayDetailScreen from './screens/BirthdayDetailScreen';
import MobileModules from './screens/MobileModulesScreen';
import MobileModuleSettingsScreen from './screens/MobileModuleSettingsScreen';
import UserScreen from './screens/UserScreen';
import UserDetailScreen from './screens/UserDetailScreen';
import UserDetailAppScreen from './screens/UserDetailAppScreen';
import LicenceScreen from './screens/licence/LicenceScreen';
import ShippingScreen from './screens/ShippingScreen';
import ShippingLogScreen from './screens/ShippingLogScreen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MailIntegrationDetailScreen from './screens/MailIntegrationDetailScreen';
import ResetPasswordScreen from './screens/login/ResetPasswordScreen';
import crypto from 'crypto-js';
import CreateConnectionScreen from './screens/CreateConnectionScreen';
import AuthSftScreen from './screens/AuthSftScreen';
import AdminStatScreen from './screens/AdminStatScreen';
import ManualScreen from './screens/ManualScreen';
import ManualViewScreen from './screens/ManualViewScreen';
import ManualEditScreen from './screens/ManualEditScreen';
import Holiday from './components/Holiday';
import ChristmasLights from './components/ChristmasLights';
import LoggingScreen from './screens/logging/LoggingScreen';
import LoggingCustomersScreen from './screens/logging/LoggingCustomersScreen';
import AuthLoginScreen from './screens/AuthLoginScreen';
import LoggingAddScreen from './screens/logging/LoggingAddScreen';
import LoggingAddScreenMobile from './screens/logging/LoggingAddScreenMobile';
import TemplateScreen from './screens/template/TemplateScreen';
import TemplateDetailScreen from './screens/template/TemplateDetailScreen';
import EticketScreen from './screens/eticket/EticketScreen';
import EticketDetailScreen from './screens/eticket/EticketDetailScreen';
import EticketWizardScreen from './screens/eticket/EticketWizardScreen';
import InformationScreen from './screens/InformationScreen';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { Box, Button, Fab, FormControl, InputLabel, ListItemText, MenuItem, Select, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, ThemeProvider, createTheme, styled } from '@mui/material';
import { CarRental, Checklist, Key, Layers, LayersClear } from '@mui/icons-material';
import Colors from './library/Colors';
import ConnectionOverviewScreen from './screens/ConnectionOverviewScreen';
import QuizScreen from './screens/extras/QuizScreen';
import ExcelImportScreen from './screens/excel/ExcelImportScreen';
import ExcelScreen from './screens/excel/ExcelScreen';
import ExcelEditScreen from './screens/excel/ExcelEditScreen';
import ExactScreen from './screens/exact/ExactScreen';
import RMAScreen from './screens/rma/RmaScreen';
import RMADetailScreen from './screens/rma/RmaDetailScreen';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import HelpScreen from './screens/help/HelpScreen';
import TodoListScreen from './screens/extras/TodoListScreen';


let lang = 'def';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowDimensions;
  }

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  text-align: center;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;


const style = {
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  width: "100%",
  p: 2,
};


function App() {
    const [login, setLogin] = useState({sft_group: "", sft_status:"0", sft_user_id:""});
    const [dbs, setDbs] = useState([]);
    const [selectedDb, setSelectedDb] = useState({settings: {modules: []}});
    const [theme, setTheme] = useState('');
    const [screen, setScreen] = useState('');
    const [key, setKey] = useState("");
    //const [menuExpanded, setMenuExpanded] = useState(true);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(false);
    const [hideTopbar, setHideTopbar] = useState(false);
    const [holidays, setHolidays] = useState((typeof getCookie("holidays") !== "undefined") ? (getCookie("holidays") === "true") : true );
    const [modal, setModal] = useState(false);
    const [reason, setReason] = useState("");
    const [selectedConnUser, setSelectedConnUser] = useState("");
    //const [user, setUser] = useState();
    const drawerWidth = 250;
    const params = useParams();
    const { height, width } = useWindowDimensions();
    //let navigate = useNavigate();

    let today = new Date();
    useEffect(()=>{
    	if(width <= 800 && !hideSidebar){
            setHideSidebar(true);
        } else if (width > 800 && hideSidebar){
            setHideSidebar(false);
        }
    }, [width]);

    const textFieldRef = useRef(null);
    useEffect(() => {
        if (modal) {
            textFieldRef.current?.focus();
        }
    }, [modal]);

    const setInputRef = useCallback((element) => {
            textFieldRef.current = element;
            if (element) {
              element.focus();
              element.select();
            }
          }, []);

    useEffect(() => {
        // Default theme fetcher
        let cachedTheme = '';
        try{
            cachedTheme = localStorage.getItem('theme');
            if(cachedTheme === null){
                cachedTheme = '';
            }
        }catch(e){
            if(SftGlobalData.debug){
                console.error("error", e);
            }
            cachedTheme = '';
        }
        
        if(cachedTheme === ''){
            const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
            if(isDarkTheme){
                setTheme('dark');
                document.body.style.backgroundColor = '#232B38';
            } else{
                setTheme('light');
                document.body.style.backgroundColor = '#f2f3f5';
            }
        } else {
            setTheme(cachedTheme);
            if(cachedTheme === 'dark'){
                document.body.style.backgroundColor = '#232B38';
            } else {
                document.body.style.backgroundColor = '#f2f3f5';
            }
        }
        

        // session key flow
        try{
            if(key===""){
                try{
                    if(typeof getCookie("cred") !== "undefined"){
                        setLogin(JSON.parse(crypto.AES.decrypt(getCookie("cred"), 'Soft295Touch!').toString(crypto.enc.Utf8)));
                    }
                }catch(e){
                    
                }
                const sidebarCollapsed = localStorage.getItem("left_sidebar-collapsed");
                let sessionKey = sessionStorage.getItem('key');
                let sessionLogin = null;
                try{
                    var data = crypto.AES.decrypt(sessionStorage.getItem('login'), 'Soft295Touch!');
                    data = data.toString(crypto.enc.Utf8);
                    if(SftGlobalData.debug){
                        console.log("logged in", data);
                    }
                    sessionLogin = JSON.parse(data);
                    
                }catch(e){
                    /*if(SftGlobalData.debug){
                        console.error('error', e);
                    }*/
                    sessionLogin = null;
                }
                if(SftGlobalData.debug){
                    console.log("Session key", sessionKey);
                    console.log("Session login", sessionLogin);
                }
                if(sessionLogin !== null){
                    try{setCookie("cred", crypto.AES.encrypt(JSON.stringify({sft_group: sessionLogin.sft_group, sft_status:sessionLogin.sft_status, sft_user_id:sessionLogin.sft_user_id}), 'Soft295Touch!').toString());}catch(ex){console.error(ex);}
                    setLogin(sessionLogin);
                    setDbs(sessionLogin.dbs);
                }

                if(sessionKey !== "" && sessionKey !== "undefined" && sessionKey !== null){
                    setKey(sessionKey);
                    if(sessionKey === SftGlobalData.defaultKey){
                        setHideSidebar(true);
                    }
                    let APIurl = SftGlobalData.baseURL_API + 'auth';
                    axios.post(APIurl, {key: sessionKey}, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('response err', typeof response.error);
                            }
                            if(typeof response.error === 'undefined'){
                                if(SftGlobalData.debug){
                                    console.log('response', response);
                                }
                                //setDbs(response.data.dbs);
                                if(response.data?.dbs?.length > 0) {
                                    setSelectedDb(response.data.dbs[0]);
                                }                                    
                            } else { 
                                notify(t('errorOccured'), 7500, "top-center", theme, 1);                       
                            }
                        });
                } else {
                    if(!window.location.href.includes("/user/password-reset") ){
                        //navigate("/"+params.lang+"/login");
                    }
                }
                if(SftGlobalData.debug){
                    console.log('sideBarCollapsed', sidebarCollapsed);
                }
                // setMenuExpanded(sidebarCollapsed === 'true' ? false : true);
            }else {
                if(key === SftGlobalData.defaultKey){
                    setHideSidebar(true);
                }
            }
        }catch(e){
            if(SftGlobalData.debug){
                console.log(e);
            }
        }
    }, [key]);

    function getEasterDate(jump) {
        const year = today.getFullYear();
        // Computus algorithm to calculate the date of Easter
        // This algorithm is based on the Gregorian calendar
        const a = year % 19;
        const b = Math.floor(year / 100);
        const c = year % 100;
        const d = Math.floor(b / 4);
        const e = b % 4;
        const f = Math.floor((b + 8) / 25);
        const g = Math.floor((b - f + 1) / 3);
        const h = (19 * a + b - d - g + 15) % 30;
        const i = Math.floor(c / 4);
        const k = c % 4;
        const l = (32 + 2 * e + 2 * i - h - k) % 7;
        const m = Math.floor((a + 11 * h + 22 * l) / 451);
        const month = Math.floor((h + l - 7 * m + 114) / 31);
        const day = ((h + l - 7 * m + 114) % 31) + 1;
    
        // Months are 0-indexed in JavaScript Date object, so we subtract 1 from the month
        let easterDate = new Date(year, month - 1, day);

        if(jump) {
            easterDate.setDate(easterDate.getDate() + 7);
        } else {
            easterDate.setDate(easterDate.getDate() - 7);
        }
    
        return (new Date(easterDate));
    }

    var christmasDateFrom = new Date("12/07/"+today.getFullYear());
    var christmasDateTo = new Date("12/31/"+(today.getFullYear()));
    var christmasDateEnd = new Date("01/15/"+(today.getFullYear()));
    var halloweenDateFrom = new Date("10/25/"+today.getFullYear());
    var halloweenDateTo = new Date("11/01/"+(today.getFullYear()));
    var valentinesDateFrom = new Date("02/14/"+today.getFullYear());
    var valentinesDateTo = new Date("02/15/"+(today.getFullYear()));
    var easterDateFrom = getEasterDate(false);
    var easterDateTo = getEasterDate(true);


    if(SftGlobalData.debug){
        console.log("height", height);
        console.log("width", width);
        console.log("params", params);
        console.log("screen", screen);
    }

    const { t, i18n } = useTranslation();

    if((params.lang === 'nl' || params.lang === 'en' || params.lang === 'fr') && lang !== params.lang){
        i18n.changeLanguage(params.lang);
        lang = params.lang;
    }

    const toggleTheme = () => {
        localStorage.removeItem('theme');
        if(theme === 'dark'){
            setTheme('light');
            document.body.style.backgroundColor = '#f2f3f5';
            localStorage.setItem('theme', 'light');
        } else {
            setTheme('dark');
            document.body.style.backgroundColor = '#232B38';
            localStorage.setItem('theme', 'dark');
        }
    }

    const cacheLogin = (loginData) => {
        setLogin(loginData);
        sessionStorage.removeItem('login');
        var data = crypto.AES.encrypt(JSON.stringify(loginData), 'Soft295Touch!');
        data = data.toString();
        sessionStorage.setItem('login', data);
        try{setCookie("cred", crypto.AES.encrypt(JSON.stringify({sft_group: loginData.sft_group, sft_status:loginData.sft_status, sft_user_id:loginData.sft_user_id}), 'Soft295Touch!').toString());}catch(ex){console.error(ex);}
        
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        
    }

    const onRefresh = () => {
        try{
            let APIurl = SftGlobalData.baseURL_API + 'login';
            let postData = {
                login: login?.login, 
                password: login?.password
            };
            axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', APIurl);
                        console.log('data', postData);
                        console.log('response', response);
                        console.log('response error', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(!response.data.is_valid){
                            notify(t('invalid_credentials'), 7500, "top-center", theme, 1); 
                        } else {
                            //props.cacheLogin(response.data); 
                            setDbs(response.data.dbs);
                        }
                    } else { 
                        notify(t('errorOccured'), 7500, "top-center", theme, 1);                      
                    }
                })
        }catch(e){
            console.error(e);
        }
    }


    const notify = (text, time, position, theme, id) => {
        toast(text, {autoClose: time, position:position, theme:theme, toastId: id});
    }

    const requestDbAccess = (reqLogin, db, reqReason) => {
        let isAdmin = reqLogin?.sft_group == "SUPER" ? true : false;
        if(reqReason !== "" || isAdmin){
            setReason("");
            if(typeof db !== "undefined" && typeof reqLogin !== "undefined"){
                try{
                    let url = SftGlobalData.baseURL_API+'permission&action=request';
                    let postData = { key: db.encrypted_key, sft_status: reqLogin.sft_status, sft_user_id: reqLogin.sft_user_id, reason: reqReason };
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            notify(response.data.message, 2000, "bottom-center", theme, 87879); 
                        });
                } catch(e){
                    console.error(e);
                    notify("DENIED: Something went wrong, check the connection to softtouch.eu...", 2000, "bottom-center", theme, 87879); 
                }
            } else {
                notify("DENIED: Not logged in correctly or invalid DB selected.", 2000, "bottom-center", theme, 87879); 
            }
        } else {
            notify("DENIED: Reason is required.", 2000, "bottom-center", theme, 87879); 
        }
    }

    const authorizeDbAccess = (user, db, reqReason) => {
        setReason("");
        if(typeof db !== "undefined" && user !== ""){
            try{
                let url = SftGlobalData.baseURL_API+'permission&action=request';
                let postData = { key: db.encrypted_key, sft_status: "2", sft_user_id: user, reason: reqReason };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        notify(response.data.message, 2000, "bottom-center", theme, 87879); 
                    });
            } catch(e){
                console.error(e);
                notify("DENIED: Something went wrong, check the connection to softtouch.eu...", 2000, "bottom-center", theme, 87879); 
            }
        } else {
            notify("DENIED: Not logged in correctly or invalid DB selected.", 2000, "bottom-center", theme, 87879); 
        }
    }

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          secondary: {
            main: Colors.sft_blue_text,
            light: Colors.sft_blue_text,
            dark: Colors.sft_blue_darker
          },
        },
        mixins: {
            MuiDataGrid: {
              containerBackground: Colors.dark_basic,
            },
        },
        typography: {
            fontFamily: [
              'Urbane Rounded', 
              'Ubuntu', 
              'sans-serif'
            ].join(','),
          },
      });

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: Colors.sft_blue,
                light: Colors.sft_blue_text,
                dark: Colors.sft_blue_darker
              },
            secondary: {
              main: Colors.sft_blue_text,
              light: Colors.sft_blue_text,
              dark: Colors.sft_blue_darker
            },
        },
        mixins: {
            MuiDataGrid: {
              containerBackground: Colors.light_basic,
            },
        },
        typography: {
            fontFamily: [
              'Urbane Rounded', 
              'Ubuntu', 
              'sans-serif'
            ].join(','),
          },
    });

    const [todoOpen, setTodoOpen] = useState(false);
    
    const [speedActions, setSpeedActions] = useState(
        login?.sft_group === "SUPER" ? [
        { 
            icon: <Key />, 
            name: 'Authorize self', 
            onClick: () => { requestDbAccess(login, selectedDb, reason); }
        },
        { 
            icon: <CarRental />, 
            name: 'Authorize another', 
            onClick: () => { setModal(true); }
        },
        { 
            icon: <Checklist />, 
            name: 'To-do list', 
            onClick: () => { toggleTodoOpen(); }
        },
      ] :
      [
        { 
            icon: <Key />, 
            name: 'Authorize self', 
            onClick: () => { setModal(true); }
        },
        { 
            icon: <Checklist />, 
            name: 'To-do list', 
            onClick: () => { toggleTodoOpen(); }
        },
      ]);

      useEffect(()=>{
        if(typeof selectedDb?.encrypted_key === "undefined"){
            setSpeedActions([
                { 
                    icon: <Checklist />, 
                    name: 'To-do list', 
                    onClick: () => { toggleTodoOpen(); }
                }
              ]);
        } else{
            setSpeedActions(login?.sft_group === "SUPER" ? [
                { 
                    icon: <Key />, 
                    name: 'Authorize self', 
                    onClick: () => { requestDbAccess(login, selectedDb, reason); }
                },
                { 
                    icon: <CarRental />, 
                    name: 'Authorize another', 
                    onClick: () => { setModal(true); }
                },
                { 
                    icon: <Checklist />, 
                    name: 'To-do list', 
                    onClick: () => { toggleTodoOpen(); }
                },
              ] :
              [
                { 
                    icon: <Key />, 
                    name: 'Authorize self', 
                    onClick: () => { setModal(true); }
                },
                { 
                    icon: <Checklist />, 
                    name: 'To-do list', 
                    onClick: () => { toggleTodoOpen(); }
                },
              ]);
        }
            
    }, [selectedDb]);

    const toggleTodoOpen = () => {
        setTodoOpen(true);
    }

    return (
        <div className="main-app-container">
            <div className={`main-content ${todoOpen ? "content-shift" : ""}`}>
                <div className={"App "}>
                    {login?.sft_status !== "0" && ((today <= christmasDateTo && today >= christmasDateFrom) || today <= christmasDateEnd) &&  holidays && <Holiday occasion={"snow"} />}
                    
                    {login?.sft_status !== "0" && ((today >= halloweenDateFrom && today <= halloweenDateTo)) && holidays && <Holiday occasion={"bat"} />}
                    {login?.sft_status !== "0" && ((today >= valentinesDateFrom && today <= valentinesDateTo)) && holidays && <Holiday occasion={"heart"} />}
                    {login?.sft_status !== "0" && ((today >= easterDateFrom && today <= easterDateTo)) && holidays && <Holiday occasion={"egg"} />}
                    {holidays && login?.sft_status !== "0" && (screen !== 'login' && screen !== 'environment' && hideSidebar === false) && (today <= christmasDateTo && today >= christmasDateFrom) && <ChristmasLights />}
                    {(screen !== 'login' && screen !== 'quiz' && hideTopbar === false) && <Header login={login} hideSidebar={hideSidebar} api={key} sftUserId={login?.sft_user_id} dbName={login?.sft_status !== "0" ? selectedDb.description : ""} group={login?.sft_group} t={t} lang={params.lang} emptyDbs={()=>{setDbs([])}} toggleMobileOpen={()=>{setMobileOpen(!mobileOpen);}} toggleDatabase={() => {setSelectedDb({settings: {modules: []}})}} screenWidth={width} mobileOpen={mobileOpen} drawerWidth={drawerWidth} theme={theme} screen={screen} dbs={dbs} toggleTheme={()=>{toggleTheme();}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} />}
                    {(screen !== 'login' && screen !== 'environment' && screen !== 'logging' && (hideSidebar === false || mobileOpen === true)) && <LeftSidebar api={key} screen={screen} group={login?.sft_group} is_sft={login?.sft_status !== "0"} toggleMobileOpen={()=>{setMobileOpen(!mobileOpen);}} mobileOpen={mobileOpen} lang={params.lang} drawerWidth={drawerWidth} modules={selectedDb.settings.modules} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}  db={selectedDb} login={login}  t={t} />}
                    {screen === 'logging' && <LeftSidebar api={key} selectDb={(db, key)=>{setSelectedDb(db); setKey(key);}} pathname={params["*"]} screen={screen} modules={[]} sft_stat={login?.sft_status} group={login?.sft_group} is_sft={login?.sft_status !== "0"} toggleMobileOpen={()=>{setMobileOpen(!mobileOpen);}} mobileOpen={mobileOpen} lang={params.lang} drawerWidth={65} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} login={login} screenWidth={width} db={selectedDb}  t={t} />}
                    <ErrorBoundary theme={theme} screenWidth={width} >
                        <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className={((hideTopbar === false) ? "app-content" :  "app-content margin_top_none ") + (width<810 ? " height_100perc " : "") + (screen === "quiz" ? " margin_top_none " : "") }>
                                    <Routes>
                                        <Route path='*' element={<LoginScreen screenWidth={width} t={t} lang={params.lang} cacheLogin={(data)=>{cacheLogin(data);}} useDbs={(dbs)=>{setDbs(dbs)}} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='dev' element={<DevScreen screenWidth={width} lang={params.lang} api={key} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='login' element={<LoginScreen screenWidth={width} t={t} lang={params.lang} cacheLogin={(data)=>{cacheLogin(data);}} useDbs={(dbs)=>{setDbs(dbs)}} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='login/:error' element={<LoginScreen screenWidth={width} t={t} lang={params.lang} cacheLogin={(data)=>{cacheLogin(data);}} useDbs={(dbs)=>{setDbs(dbs)}} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='user/password-reset/:token' element={<ResetPasswordScreen screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='environment' element={<EnvironmentScreen todo={todoOpen} onRefresh={()=>{onRefresh();}} maxHeight={height} sftUserId={login?.sft_user_id} group={login?.sft_group} sftStatus={login?.sft_status} screenWidth={width} t={t} lang={params.lang} dbs={dbs} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} selectDb={(db, key)=>{setSelectedDb(db); setKey(key);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='env' element={<EnvironmentScreen todo={todoOpen} onRefresh={()=>{onRefresh();}} maxHeight={height} sftUserId={login?.sft_user_id} noAutoRedirect={true} group={login?.sft_group} sftStatus={login?.sft_status} screenWidth={width} t={t} lang={params.lang} dbs={dbs} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} selectDb={(db, key)=>{setSelectedDb(db); setKey(key);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='home' element={<HomeScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='auth/:key' element={<AuthScreen lang={params.lang} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='auth/:key/:redirect' element={<AuthScreen lang={params.lang} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='authentication/:key' element={<AuthScreen lang={params.lang} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='authentication/:key/:redirect' element={<AuthScreen lang={params.lang} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='authentication/:key/:redirect/:val' element={<AuthScreen lang={params.lang} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='authentication/:key/:redirect/:val/:val2' element={<AuthScreen lang={params.lang} theme={theme} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />

                                        <Route path='mobile' element={<MobileModules screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} groupId={'0000'} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='module/:module' element={<MobileModuleSettingsScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} groupId={'0000'} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='module/:module/:groupId' element={<MobileModuleSettingsScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} groupId={'0000'} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />

                                        <Route path='users' element={<UserScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='user-detail/:userId' element={<UserDetailScreen group={login?.sft_group} isSft={login?.sft_status !== "0"} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='user-detail/:userId/:appId' element={<UserDetailAppScreen defaults={false} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='app-defaults' element={<UserDetailAppScreen defaults={true} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />

                                        <Route path='shipping-log' element={<ShippingLogScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} hideSidebar={hideSidebar} setHideSidebar={(hideSidebar)=>{setHideSidebar(hideSidebar);}} hideTopbar={hideTopbar} setHideTopbar={(hideTopbar)=>{setHideTopbar(hideTopbar);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='shipping' element={<ShippingLogScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} hideSidebar={hideSidebar} setHideSidebar={(hideSidebar)=>{setHideSidebar(hideSidebar);}} hideTopbar={hideTopbar} setHideTopbar={(hideTopbar)=>{setHideTopbar(hideTopbar);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='shipping/new' element={<ShippingScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} hideSidebar={hideSidebar} setHideSidebar={(hideSidebar)=>{setHideSidebar(hideSidebar);}} hideTopbar={hideTopbar} setHideTopbar={(hideTopbar)=>{setHideTopbar(hideTopbar);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='shipping/:receiptNr' element={<ShippingScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} hideSidebar={hideSidebar} setHideSidebar={(hideSidebar)=>{setHideSidebar(hideSidebar);}} hideTopbar={hideTopbar} setHideTopbar={(hideTopbar)=>{setHideTopbar(hideTopbar);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='shipping/:receiptNr/:storeId' element={<ShippingScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} hideSidebar={hideSidebar} setHideSidebar={(hideSidebar)=>{setHideSidebar(hideSidebar);}} hideTopbar={hideTopbar} setHideTopbar={(hideTopbar)=>{setHideTopbar(hideTopbar);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />

                                        <Route path='excel' element={<ExcelScreen login={login} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='excel/:mod' element={<ExcelImportScreen login={login} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='rma' element={<RMAScreen login={login} screenWidth={width} hideSidebar={hideSidebar} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='rma/:search' element={<RMAScreen login={login} screenWidth={width} hideSidebar={hideSidebar} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='rma/detail/:rmaId' element={<RMADetailScreen login={login} hideSidebar={hideSidebar} maxHeight={height} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='rma/detail/:rmaId/:storeId' element={<RMADetailScreen login={login} hideSidebar={hideSidebar} maxHeight={height} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        
                                        {(login.sft_user_id !== "" || key !== "") && <Route path='kb' element={<HelpScreen screen={screen} hideSidebar={hideSidebar} screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} login={login}/>} />}
                                        {(login.sft_user_id !== "" || key !== "") && <Route path='kb/:id' element={<HelpScreen screen={screen} hideSidebar={hideSidebar} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(login.sft_user_id !== "" || key !== "") && <Route path='kb/:id/:value' element={<HelpScreen screen={screen} login={login} hideSidebar={hideSidebar} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}

                                        {(typeof login.sft_user_id !== "undefined") && <Route path='eticket' element={<EticketScreen screen={screen} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined") && <Route path='eticket-wizard' element={<EticketWizardScreen screen={screen} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined") && <Route path='eticket/:listId/:elementId' element={<EticketDetailScreen screen={screen} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {
                                            //---------------------------
                                            // SOFTTOUCH ONLY ROUTES    |
                                            //---------------------------
                                        }
                                        <Route path='lic-auth/:key' element={<AuthSftScreen lang={params.lang} t={t} theme={theme} cacheLogin={(data)=>{cacheLogin(data);}} useDbs={(dbs)=>{setDbs(dbs)}}  changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        <Route path='override_core_protection/:redirect/:key' element={<AuthLoginScreen lang={params.lang} t={t} theme={theme} cacheLogin={(data)=>{cacheLogin(data);}} useDbs={(dbs)=>{setDbs(dbs)}}  changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='environment/:id' element={<CreateConnectionScreen screenWidth={width} t={t} lang={params.lang} dbs={dbs} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} sftStatus={login?.sft_status} setDbs={(databases)=>{setDbs(databases);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='logging' element={<LoggingScreen login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && width > 900 && <Route path='logging/add' element={<LoggingAddScreen setSelectedDb={(db)=>{setSelectedDb(db);}} screenWidth={width} login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && width <= 900 && <Route path='logging/add' element={<LoggingAddScreenMobile screenWidth={width} login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='logging/logs' element={<LoggingScreen login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='logging/customers' element={<LoggingCustomersScreen login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='licence' element={<LicenceScreen onRefresh={()=>{onRefresh();}} login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='admin-stats' element={<AdminStatScreen userId={login?.sft_user_id} loginObj={login} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='adminstats' element={<AdminStatScreen userId={login?.sft_user_id} loginObj={login} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='connections' element={<ConnectionOverviewScreen login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}

                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='integrations' element={<IntegrationScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='channable-detail/:listId' element={<IntegrationDetailScreen integration="channable" screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='google-detail/:listId' element={<IntegrationDetailScreen integration="google" screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='facebook-detail/:listId' element={<IntegrationDetailScreen integration="facebook" screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}

                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='mailchimp' element={<MailchimpScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='mail-integration/:id' element={<MailIntegrationDetailScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='mail-integrations' element={<MailIntegrationsScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='mail-integrations/:id' element={<MailIntegrationsSetup screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}

                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='birthday' element={<BirthdayScreen screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='birthday-detail/:listId' element={<BirthdayDetailScreen screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='manuals' element={<ManualScreen screen={screen} screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} login={login}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='manuals/view/:id' element={<ManualViewScreen screen={screen} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='manuals/edit/:id' element={<ManualEditScreen screen={screen} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                    
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='templates' element={<TemplateScreen screen={screen} screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} login={login}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='templates/edit/:id' element={<TemplateDetailScreen screen={screen} login={login} screenWidth={width} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='user-info' element={<InformationScreen screen={screen} screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} login={login} holidays={holidays} toggleHolidays={()=>{setHolidays(!holidays); setCookie("holidays", !holidays);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='quiz' element={<QuizScreen login={login} screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} setHideSidebar={(hideSidebar)=>{setHideSidebar(hideSidebar);}}/>} />}
                                    
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0") && <Route path='excel/edit/:mod' element={<ExcelEditScreen login={login} screenWidth={width} t={t} lang={params.lang} db={selectedDb} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                        {(typeof login.sft_user_id !== "undefined" && login?.sft_status !== "0" && login?.sft_group === "SUPER") && <Route path='exact' element={<ExactScreen setSelectedDb={(db)=>{setSelectedDb(db);}} screenWidth={width} login={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} t={t} lang={params.lang} selectedDb={selectedDb} api={key} theme={theme} dbs={dbs} setDbs={(databases)=>{setDbs(databases);}} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}}/>} />}
                                    </Routes> 
                                </div>
                            </LocalizationProvider>
                        </ThemeProvider>
                    </ErrorBoundary>
                    {(screen !== 'login' && screen !== 'quiz') && <Footer lang={lang} theme={theme} screen={screen} db={selectedDb}/>}
                    {(typeof selectedDb?.encrypted_key !== "undefined" && login?.sft_status === "3" && typeof login?.sft_user_id !== "undefined" && login?.sft_group !== "SUPER") && <Box disabled={modal} onClick={()=>{if(login?.sft_group === "SUPER" ) {requestDbAccess(login, selectedDb, reason)}else{setModal(true)}}} style={{position: 'fixed', left: 14, bottom: 24, zIndex: 10000}} sx={{ '& > :not(style)': { m: 1 } }}>
                        {(screen !== 'login' && screen !== 'environment' && screen !== 'quiz') && <Fab disabled={modal} size="medium" sx={{ backgroundColor: Colors.sft_blue, color: Colors.sft_blue_text }} aria-label="Request access">
                            <Key />
                        </Fab>}
                    </Box>}
                    {(typeof login?.sft_user_id !== "undefined" && login?.sft_status !== "3" && login?.sft_status !== "0" && screen !== 'login' && screen !== 'quiz') && (
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ '& > :not(style)': { m: 1 }  }}
                            icon={<SpeedDialIcon icon={<Layers />} openIcon={<LayersClear />}/>}
                            size="medium"
                            style={{position: 'fixed', left: 14, bottom: 24, zIndex: 10000}}>
                            {speedActions.map((action) => (
                              <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={()=>{action.onClick();}}
                              />
                            ))}
                        </SpeedDial>)}
                    
                    <StyledModal
                        aria-labelledby="unstyled-modal-title"
                        aria-describedby="unstyled-modal-description"
                        open={modal}
                        onClose={()=>{setModal(false)}}
                        BackdropComponent={Backdrop}
                        >
                        <Box sx={style} style={{minHeight: 300, backgroundColor: (theme === 'dark' ? Colors.dark_basic : Colors.light_basic)}} className={theme + " slide-in-bottom"}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                {login?.sft_group !== "SUPER" && <h2>Request access</h2>}
                                <p style={{margin: 5}}>You are requesting access to: <b>{selectedDb.description}</b></p>
                                <div style={{maxWidth: 350, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', textAlign: 'left'}}>
                                    {login?.sft_group === "SUPER" && <FormControl style={{ width: '100%', marginTop: 10}}>
                                        <InputLabel id="demo-simple-select-label-art">{t('user')}:</InputLabel>
                                        <Select id="User"
                                        className={theme+"_accent "+theme+"_text _100perc"}
                                        labelId="demo-simple-select-label-art" 
                                        label={t('user')} 
                                        value={selectedConnUser} 
                                        onChange={(e) => {setSelectedConnUser(e.target.value)}} 
                                        style={{ maxHeight: 43 }}
                                        >
                                            <MenuItem value={""} name={"-"}><ListItemText primary={"-"} /></MenuItem>
                                            {/*<MenuItem value={"1018"} name={"sft_bert_vdv"}><ListItemText primary={"Bert (sft_bert_vdv)"} /></MenuItem>*/}
                                            <MenuItem value={"1033"} name={"Demo (sft_demo)"}><ListItemText primary={"Demo (sft_demo)"} /></MenuItem>
                                            <MenuItem value={"1030"} name={"sft_gianni_r"}><ListItemText primary={"Gianni (sft_gianni_r)"} /></MenuItem>
                                            <MenuItem value={"1017"} name={"sft_louis_w"}><ListItemText primary={"Louis (sft_louis_w)"} /></MenuItem>
                                            <MenuItem value={"1027"} name={"sft_ruben_dr"}><ListItemText primary={"Ruben (sft_ruben_dr)"} /></MenuItem>
                                            <MenuItem value={"1029"} name={"sft_sander_vdp"}><ListItemText primary={"Sander (sft_sander_vdp)"} /></MenuItem>
                                            <MenuItem value={"1028"} name={"sft_thibeau_db"}><ListItemText primary={"Thibeau (sft_thibeau_db)"} /></MenuItem>
                                            {/*<MenuItem value={""} name={""}><ListItemText primary={""} /></MenuItem>*/}
                                        </Select>
                                    </FormControl>}
                                    <TextField
                                        required
                                        multiline
                                        minRows={2}
                                        maxRows={2}
                                        inputProps={{ maxLength: 255 }}
                                        autoComplete="off" 
                                        style={{width: '100%', marginTop: 5, marginBottom: 5}}
                                        className={theme+ '_accent '}
                                        id="reason"
                                        label={t('reason')}
                                        size={'small'}
                                        sx={(theme==='light') ? {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.light_text
                                              },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.light_text
                                             }, borderRadius: 3, marginTop: 0} 
                                            :
                                            {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.dark_text
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.dark_text_accent
                                                }, borderRadius: 3, marginTop: 0}}
                                        value={reason}
                                        onChange={(event)=>{setReason(event.target.value)}}
                                        inputRef={setInputRef} 
                                        />
                                    <Button 
                                        variant="outlined" 
                                        sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                                        style={{width: '100%', marginTop: 5}} 
                                        onClick={()=>{
                                            setModal(false); 
                                            if(login?.sft_group !== "SUPER") requestDbAccess(login, selectedDb, reason);
                                            else authorizeDbAccess(selectedConnUser,selectedDb, reason);
                                        }}>Request DB access</Button>
                                </div>
                            </div>
                            {screen !== 'login' && <Footer lang={lang} theme={theme} screen={screen} db={selectedDb}/>}
                        </Box>
                    </StyledModal>
                    <ToastContainer style={{marginTop: ((screen !== 'login' && hideTopbar === false) ? 50 : 0)}} />
                </div>
                {screen !== 'login' && <div className={`todo-sidebar ${todoOpen ? "open" : "hidden"}`} style={{ zIndex: 998, overflowY: 'auto', padding: 10, backgroundColor: theme === "dark" ? Colors.dark_basic : Colors.light_basic }}>
                    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
                        <TodoListScreen login={login?.login} loginObj={login} userId={login?.sft_user_id} username={login?.first_name} group={login?.sft_group} screenWidth={width} t={t} lang={params.lang} api={key} theme={theme} changeScreen={(screenName)=>{setScreen(screenName);}} notify={(text, time, position, theme, id) => {notify(text, time, position, theme, id);}} close={()=>{setTodoOpen(false);}}/>
                    </ThemeProvider>
                </div>}
            </div>
        </div>
    );
}

export default App;
