import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { ChatBubble } from '@mui/icons-material';

export default function FloatingLogButton(props) {
  return (
    <Box disabled={props.disabled} onClick={()=>{props?.onClick();}} style={{position: 'fixed', right: 14, bottom: 24, zIndex: 999}} sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab size="medium" color={"primary"} aria-label="log">
        <ChatBubble />
      </Fab>
    </Box>
  );
}