import React, { useState, useEffect } from 'react';
import '../../screens/logging/logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { set } from 'date-fns';
import { Check, Save } from '@mui/icons-material';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
};

function CustomEditComponent(props) {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event) => {
        if(props.row.user_id === props.user){
            const newValue = event.target.value; // The new value entered by the user
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
    };

    const saveValueChange = () => {
        if(props.orig !== value){
            if (window.confirm("Save changes?") === true) {
                try{
                    //console.log("props", props);
                    let url = SftGlobalData.baseURL_API+'logging&action=updateLog';
                    let postData = {id: props.row.id, field: props.sql, value: value.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"), user_id: props.row.user_id, sft: props.login?.sft_status};
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            props.loadTasks();
                        });
                } catch(e){
                    console.error(e);
                }
            } else {
                apiRef.current.setEditCellValue({ id, field, value: props.orig });
            }
        }
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13){
            saveValueChange();
        }
    }
  
    return <input type="text" value={value} onChange={handleValueChange} onKeyDown={(event)=>{handleEnter(event);}} onBlur={()=>{saveValueChange()}}/>;
}

function CustomEditComponentPriority(props) {
    const { id, field, value } = props;
    const [val, setVal] = useState(value);
  
    const handleValueChange = (event) => {

        if(SftGlobalData.debug){
            console.log("id", id);
            console.log("field", field);
            console.log("value", value);
        }

        const newValue = event.target.value; // The new value entered by the user
        //apiRef.current.setEditCellValue({ id, field, value: newValue });
        setVal(newValue);
        saveValueChange(newValue);
    };

    const saveValueChange = (newVal) => {
        try{
            //console.log("props", props);
            let url = SftGlobalData.baseURL_API+'logging&action=updateLog';
            let postData = {id: props.row.id, field: props.sql, value: newVal, user_id: props.row.user_id, sft: props.login?.sft_status};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.loadTasks();
                });
        } catch(e){
            console.error(e);
        }
    }

  
    return (<select type="text" value={val} onChange={handleValueChange} style={{width: '100%', margin: 0, paddingLeft: 0, paddingRight: 0}}>
                <option value="L">{props.t('low')}</option>
                <option value="M">{props.t('medium')}</option>
                <option value="H">{props.t('high')}</option>
            </select>);
}

function CustomEditComponentUser(props) {
    const { id, field, value } = props;
    const [val, setVal] = useState(value);
  
    const handleValueChange = (event) => {
        if(SftGlobalData.debug){
            console.log("id", id);
            console.log("field", field);
            console.log("value", value);
        }

        const newValue = event.target.value; // The new value entered by the user
        setVal(newValue);
        saveValueChange(newValue);
    };

    const saveValueChange = (newVal) => {
        try{
            //console.log("props", props);
            let url = SftGlobalData.baseURL_API+'logging&action=updateLog';
            let postData = {id: props.row.id, field: props.sql, value: newVal, user_id: props.row.user_id, sft: props.login?.sft_status};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.loadTasks();
                });
        } catch(e){
            console.error(e);
        }
    }

    const usersMap = props.users.filter(row => row.softtouch === (props.row.cust_id >= 10000 ? "3" : "2")).map((row, index) => {
        return (
            <option value={row.user_id}>{row.name}</option>
        )
    });

    return (<select type="text" value={val} onChange={handleValueChange} style={{width: '100%', margin: 0, paddingLeft: 0, paddingRight: 0}}>
                {usersMap}
            </select>);
}

const TaskTable = (props) =>  {
    const [pageSize, setPageSize] = useState(10);
    const [modal, setModal] = useState(false);
    const [selectedLog, setSelectedLog] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [remark, setRemark] = useState("");
    const [solution, setSolution] = useState("");
    const [caller, setCaller] = useState("");
    const [phone, setPhone] = useState("");
    const [teamviewerId, setTeamviewerId] = useState("");
    const [teamViewerPass, setTeamViewerPass] = useState("");

    const gridColumns = [
        {
            field: 'log_date',
            headerName: props.t('date'),
            headerClassName: 'bold',
            minWidth: 150,
            width: 150,
            align: "left",
            visible: false,
            renderCell: (params)=>{return <div onClick={() => {setSelectedLog(params.row); setModal(true);}}>{params.row.log_date}</div>}
        },
        {
            field: 'customer',
            headerName: props.t('customer'),
            minWidth: 175,
            width: 225,
            align: "left",
            renderCell: (params)=>{return <div onClick={() => {setSelectedLog(params.row); setModal(true);}}>{params.row.customer}</div>}
        },
        {
            field: 'user_id',
            headerName: props.t('employee'),
            minWidth: 100,
            width: 110,
            align: "left",
            editable: true,
            renderCell: (params)=>{return <CustomEditComponentUser row={params.row} login={props.login} sql={"user_id"} users={props.users} user={props.userId} softtouch={props.userSft} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'log_tags',
            headerName: props.t('tags'),
            minWidth: 160,
            align: "left",
        },
        {
            field: 'cust_store',
            headerName: props.t('store'),
            minWidth: 175,
            width: 225,
            align: "left",
        },
        {
            field: 'log_remark',
            headerName: props.t('problem'),
            width: 500,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} key={params.field} orig={params.row.log_remark} sql={"remark"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'log_time',
            headerName: props.t('duration'),
            minWidth: 75,
            width: 75,
            align: "left",
        },
        {
            field: 'caller',
            headerName: props.t('caller'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} key={params.field} orig={params.row.caller} sql={"caller"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'phone',
            headerName: props.t('phone'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} key={params.field} orig={params.row.phone} sql={"phone"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'teamviewer_id',
            headerName: props.t('teamviewer_id'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} key={params.field} orig={params.row.teamviewer_id} sql={"teamviewer_id"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'teamviewer_pass',
            headerName: props.t('teamviewer_pass'),
            minWidth: 100,
            width: 100,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} key={params.field} orig={params.row.teamviewer_pass} sql={"teamviewer_ww"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'priority',
            headerName: props.t('priority'),
            headerClassName: 'text_center',
            minWidth: 110,
            width: 110,
            align: "left",
            editable: true,
            renderCell: (params)=>{return <CustomEditComponentPriority t={props.t} login={props.login} key={params.field} sql={"priority"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'log_solution',
            headerName: props.t('solution'),
            width: 500,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} key={params.field} orig={params.row.log_solution} sql={"solution"} user={props.userId} loadTasks={()=>{props.loadTasks();}} {...params}/>}
        },
        {
            field: 'confirm',
            headerName: props.t('complete'),
            minWidth: 85,
            width: 85,
            align: "center",
            renderCell: (params)=>{return props.userId === params.row.user_id ? <Button key={params.field} onClick={() => {completeTask(params.row.id);}}>{(props.userId === params.row.user_id) && <CheckCircleOutlineIcon />}</Button> : <div></div>}
        },
      ];
    const apiRef = useGridApiRef();

    const completeTask = (id) => {
        if (window.confirm(props.t('confirm_complete_task')) === true) {
            try{
                let url = SftGlobalData.baseURL_API+'logging&action=updateLog';
                let postData = {id: id, field: 'completed', value: 1, user_id: props.userId, sft: props.login?.sft_status};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        props.loadTasks();
                    });
            } catch(e){
                console.error(e);
            }
        }
    }
    
    useEffect(()=>{
        try{
            if(typeof props?.openModal !== "undefined"){
                apiRef.current.subscribeEvent(
                    'columnOrderChange',
                    ()=>{visibilityChangedHandler();},
                );
            }
        }catch(ex){
    
        }
    }, [apiRef]);

    useEffect(()=>{
        setRemark(selectedLog.log_remark);
        setCaller(selectedLog.caller);
        setPhone(selectedLog.phone);
        setTeamviewerId(selectedLog.teamviewer_id);
        setTeamViewerPass(selectedLog.teamviewer_pass);
        setSolution(selectedLog.log_solution);
    },[selectedLog]);


    const updateLog = (process) => {
        if (window.confirm("Save changes?") === true) {
            try{
                let url = SftGlobalData.baseURL_API+'logging&action=updateLogFull';
                let postData = {
                    id: selectedLog.id, 
                    remark: remark,
                    solution: solution,
                    caller: caller,
                    phone: phone,
                    teamviewer_id: teamviewerId,
                    teamviewer_pass: teamViewerPass,
                    process: process,
                    user_id: selectedLog.user_id, 
                    sft: props.login?.sft_status
                };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        props.loadTasks();
                        setModal(false);
                    });
            } catch(e){
                console.error(e);
            }
        }
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        //console.log("cookie", cname + "=" + cvalue + ";" + expires + ";path=/");
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const visibilityChangedHandler = () => {
        if(typeof props?.openModal !== "undefined"){
            let temp = apiRef.current.exportState();
            if(typeof temp.columns.columnVisibilityModel === "undefined"){
                temp.columns.columnVisibilityModel = {};
            }
            try{
                temp.preferencePanel.open = false;
            }catch(e){
        
            }
            try{
                delete temp.pagination;
            }catch(e){
                console.error(e);
            }
            setCookie("initialStateTasks", JSON.stringify(temp), 100000);
        }
    }
    
    function DataGrid() {
        return (
          <div>
            <DataGridPro
                apiRef={apiRef}
                className={props.theme}
                initialState={props.initialGrid}
                columns={gridColumns}
                onColumnWidthChange={()=>{visibilityChangedHandler();}}
                onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                rows={props.data.filter(row => (row.customer?.match(new RegExp(searchValue, "i")) || row.log_remark?.match(new RegExp(searchValue, "i")) || row.log_date?.match(new RegExp(searchValue, "i")) || row.log_owner?.match(new RegExp(searchValue, "i")) || row.customer_store?.match(new RegExp(searchValue, "i")) || row.log_tags?.match(new RegExp(searchValue, "i"))))
                .map((row, index) => {return {
                        id: row.id,
                        cust_id: row.customer_id,
                        customer: row.customer,
                        cust_store_id: row.customer_store_id,
                        cust_store: row.customer_store,
                        log_date: row.log_date,
                        log_tags: row.log_tags.replaceAll("[\"", "").replaceAll("\"", "").slice(0,-1).replaceAll(",", ", "),
                        log_remark: row.log_remark,
                        log_time: row.log_time,
                        log_owner: row.log_owner,
                        log_solution: row.log_solution,
                        caller: row.caller,
                        phone: row.phone,
                        priority: row.priority,
                        teamviewer_id: row.teamviewer_id,
                        teamviewer_pass: row.teamviewer_pass,
                        user_id: row.user_id
                    }
                })}
                //{...data}
                rowHeight={38}
                autoHeight={true}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                pageSizeOptions={typeof props?.openModal !== "undefined" ? [100, 150, 200] : [10]}
                loading={props.loading}
                />
          </div>
        );
    }
    
    return (
        <div>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modal}
                onClose={()=>{setModal(false);}}
                BackdropComponent={Backdrop}
                >
                <Box 
                    sx={style} 
                    style={{minWidth: 1000}} 
                    className={props.theme + "  box_fade-in"}>
                    <h2 className={props.theme} >{props.t('log_details')}</h2>
                    <hr/>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('date')}</p>
                        <p className='_75perc'>{selectedLog.log_date}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('customer')}</p>
                        <p className='_75perc'>{selectedLog.customer}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('store')}</p>
                        <p className='_75perc'>{selectedLog.cust_store}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('user')}</p>
                        <p className='_75perc'>{selectedLog.log_owner}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_5px height_30px'>
                        <p className='_25perc bold'>{props.t('tags')}</p>
                        <p className='_75perc'>{selectedLog.log_tags}</p>
                    </div>
                    <div className='text_left log__container_options margin_bottom_10px height_30px'>
                        <p className='_25perc bold'>{props.t('duration')}</p>
                        <p className='_75perc'>{selectedLog.log_time}</p>
                    </div>

                    <br/>

                    <div className='text_left log__container_options margin_bottom_5px'>
                        <TextField
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc'}
                            id="caller"
                            label={props.t('caller')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={caller}
                            onChange={(event)=>{setCaller(event.target.value)}}
                            />  
                    </div>
                    <div className='text_left log__container_options margin_bottom_10px'>
                        <TextField
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc'}
                            id="phone"
                            label={props.t('phone')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={phone}
                            onChange={(event)=>{setPhone(event.target.value)}}
                            />  
                    </div>
                    <div className='text_left log__container_options margin_bottom_10px'>
                        <TextField
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc'}
                            id="teamviewerId"
                            label={props.t('teamviewer_id')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={teamviewerId}
                            onChange={(event)=>{setTeamviewerId(event.target.value)}}
                            />  
                    </div>
                    <div className='text_left log__container_options margin_bottom_10px'>
                        <TextField
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc'}
                            id="teamviewerPass"
                            label={props.t('teamviewer_pass')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={teamViewerPass}
                            onChange={(event)=>{setTeamViewerPass(event.target.value)}}
                            />  
                    </div>

                    <hr/>
                    <div className='text_left margin_bottom_5px log__container_options'>
                        <TextField
                            multiline
                            minRows={3}
                            maxRows={3}
                            inputProps={{ maxLength: 255 }}
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc '}
                            id="remark"
                            label={props.t('problem')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={remark}
                            onChange={(event)=>{setRemark(event.target.value)}}
                            />
                    </div>
                    <div className='text_left margin_bottom_5px log__container_options'>
                        <TextField
                            multiline
                            minRows={2}
                            maxRows={2}
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc '}
                            id="solution"
                            label={props.t('solution')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={solution}
                            onChange={(event)=>{setSolution(event.target.value)}}
                            />  
                    </div>
                    <hr/>
                    <Button
                        style={{width: "100%", marginBottom: 5}}
                        sx={{ borderRadius: 3, border: '1px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                        variant="outlined"
                        startIcon={<Save style={{color: Colors.sft_blue}} />}
                        onClick={() => {updateLog(false);}}
                      >
                        {props.t("save")}
                    </Button>
                    <Button
                        style={{width: "100%"}}
                        sx={{ borderRadius: 3, border: '1px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                        variant="outlined"
                        startIcon={<Check style={{color: Colors.sft_blue}} />}
                        onClick={() => {updateLog(true);}}
                      >
                        {props.t("process")}
                    </Button>
                </Box>
            </StyledModal>
            {typeof props?.openModal !== "undefined" && <div className='log__container_options'>
                <TextField
                    id="input-with-icon-textfield"
                    autoComplete="off" 
                    className={props.theme+ '_accent margin_right__10px'}
                    sx={{ borderRadius: 3, marginTop: 0 }}
                    size={'small'}
                    fullWidth
                    type="search"
                    placeholder={props.t('search')}
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{color: Colors.sft_blue}} />
                        </InputAdornment>
                      ),
                    }}
                />

                <Button
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                    variant="outlined"
                    startIcon={<AddIcon style={{color: Colors.sft_blue}} />}
                    onClick={() => {props.openModal();}}
                  >
                    {props.t("add")}
                </Button>
            </div>}

            
            {DataGrid()}
        </div>
    );
}

export default TaskTable;
