import React, { useEffect, useState } from 'react';

import SftGlobalData from '../library/SftGlobalData';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LinearProgressWithLabel from '../components/connection/LinearProgressWithLabel';
import Colors from '../library/Colors';
import { MenuItem, Select, Switch, Autocomplete } from '@mui/material';



const CreateConnectionScreen = (props) =>  {
    const [disable, setDisable] = useState(false);
    const [progress, setProgress] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    let navigate = useNavigate();
    const params = useParams();

    const [customer, setCustomer] = useState("0");
    const [customers, setCustomers] = useState([]);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [daysValid, setDaysValid] = useState("30");
    const [monthlyRent, setMonthlyRent] = useState(props.sftStatus === "3");

    const [optionCreateDb, setOptionCreateDb] = useState(true);
    const [host, setHost] = useState("sft5.softtouch.eu");
    const [database, setDatabase] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [port, setPort] = useState("3306");

    const [modEdi, setModEdi] = useState(false);
    const [modWeb, setModWeb] = useState(false);
    const [modMarketing, setModMarketing] = useState(false);
    const [modB2B, setModB2B] = useState(false);

    const [optionTraining, setOptionTraining] = useState(true);
    const [optionSMS, setOptionSMS] = useState(true);
    const [optionAPI, setOptionAPI] = useState(false);

    const [step, setStep] = useState(props.t('START'));
    const [error, setError] = useState(false);
    const [output, setOutput] = useState("");

    useEffect(()=>{
        let url = SftGlobalData.baseURL_API + 'client&action=fetchCustomers';
        let postData = {
                        type: props.sftStatus,
                        override: "EG68$#sO!*eCXx",
                        key: ""
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setCustomers(response.data);
            try{
                if(params.id.slice(-1) === "*"){
                    setCustomer(params.id.slice(0, -1));
                    let custDescr = response.data.filter((e)=>e.klnr === params.id.slice(0, -1));
                    custDescr = custDescr[0].firmanaam;
                    setName(custDescr.replaceAll("\\", "").replaceAll("'", ""));
                    setHost("sft5.softtouch.eu");
                    let dbName = custDescr.replaceAll(' ', '').replace(/[^a-z]/gi,'').replaceAll("\\", "").replaceAll("'", "");
                    setDatabase(dbName.length >= 20 ? dbName.substring(0,20) : dbName);
                    setUser(generateDbCredential(14));
                    setPassword(generateDbCredentialSpecialChars(16));
                    setPort("3306");
                }
            }catch(e){
                
            }
            
        });
        
    }, [props.login, params.id, props.sftStatus]);



    function generateSMSToken(){
        var length           = 9;
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            if(i === 4){
                result += '-';
            }else{
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
        }
        
        return result;
    }

    function generateDbCredential(length){
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            if(i === 4){
                result += '-';
            }else{
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
        }
        
        return result;
    }

    function generateDbCredentialSpecialChars(length){
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-_';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            if(i === 4){
                result += '-';
            }else{
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
        }
        
        return result;
    }


    const onStart = async() => {
        

        if(name.length > 0 && database.length > 0 && user.length > 0 && password.length > 0 && port.length > 0 && daysValid > 0 && customer !== "0"){
            setDisable(true);
            setProgress(5);
            setError(false);
            if(optionCreateDb){
                createDb();
            } else {
                createClient("");
            }
        } else {
            alert(props.t('RequiredFieldsMissing'));
        }

        
        
    }

    const createDb = async() => {
        setStep(props.t('CreateUserAndDb'))
        let url = SftGlobalData.baseURL_API + 'connection&action=createDb';
        let postData = {
                        zone: customer >= 10000 ? "SoftTouchWallonie" : "SoftTouchVlaanderen",
                        override: "EG68$#sO!*eCXx",
                        host: host.replaceAll("\\", "").replaceAll("'", ""), 
                        database: database.replaceAll("\\", "").replaceAll("'", ""), 
                        user: user.replaceAll("\\", "").replaceAll("'", ""), 
                        password: password.replaceAll("\\", "").replaceAll("'", ""), 
                        port: port
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setOutput(response.data.message);
            if(response.data.db_creation_success){
                setStep(props.t('CreateUserAndDbRestore'));
                setProgress(25);
                createClient(response.data.message);
            } else {
                setStep(props.t('FailedCreateUserDbRestore'));
                setError(true);
                setDisable(false);
            }
            
        });
        
        
    }

    const createClient = async (message) => {
        setStep(props.t('CreateClientLicenceModule'));
        let url = SftGlobalData.baseURL_API + 'connection&action=createLicClient';
        let postData = {
                        zone: customer >= 10000 ? "SoftTouchWallonie" : "SoftTouchVlaanderen",
                        override: "EG68$#sO!*eCXx",
                        customer: customer,
                        name: name.replaceAll("\\", "").replaceAll("'", ""), 
                        description: description.replaceAll("\\", "").replaceAll("'", ""), 
                        days_valid: daysValid, 
                        monthly_rent: monthlyRent ? "1" : "0", 
                        mod_b2b: modB2B ? "1" : "0",
                        mod_marketing: modMarketing ? "1" : "0",
                        mod_edi: modEdi ? "1" : "0",
                        mod_web: modWeb ? "1" : "0",
                        host: host.replaceAll("\\", "").replaceAll("'", ""), 
                        database: database.replaceAll("\\", "").replaceAll("'", ""), 
                        user: user.replaceAll("\\", "").replaceAll("'", ""), 
                        password: password.replaceAll("\\", "").replaceAll("'", ""), 
                        port: port, sftUser: props.username, sftUserId: props.userId
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setOutput(message + "\n\r" + response.data.message);
            setStep(props.t('CreateLicenceModule'));
            setProgress(45);
            try{
                let newDbs = props.dbs;
                newDbs.push(response.data.db);
                props.setDbs(newDbs);
            }catch(e){
                console.error(e);
            }
            if(optionSMS){
                createSMS(response.data.db, message + "\n\r" + response.data.message);
            } else if(optionAPI){
                createAPI(response.data.db, message + "\n\r" + response.data.message);
            } else {
                createLicences(response.data.db, message + "\n\r" + response.data.message);
            }
        });
    }

    const createSMS = async(db, message) => {
        setStep(props.t('CreateSmsCredentials'));

        try{
            let url = SftGlobalData.baseURL_API+'client&action=updateSmsSettings';
            let postData = {
                key: db.encrypted_key,
                provider: "spryng",
                app_id: "",
                token: generateSMSToken(),
                prepaid: false
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setOutput(message + "\n\rSMS credentials created.");
                    setStep(props.t('CreatedSmsCredentials'));
                    setProgress(60);
                    if(optionAPI){
                        createAPI(db, message + "\n\rSMS credentials created.");
                    } else {
                        createLicences(db, message + "\n\rSMS credentials created.");
                    }
                });
        } catch(e){
            console.error(e);
        }
        
        
    }

    const createAPI = async(db, message) => {
        setStep(props.t('CreateApiAccountId'));
        try{
            let url = SftGlobalData.baseURL_API+'client&action=createApiAccount';
            let postData = {key: db.encrypted_key}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setOutput(message + "\n\rAPI Account ID created.");
                    setStep(props.t('CreateApiToken'));
                    setProgress(70);

                    url = SftGlobalData.baseURL_API + 'client&action=addApiUser';
                    postData = {
                        key: db.encrypted_key,
                        first_name: name,
                        last_name: "SoftTouch",
                        email: database+db.id+"@softtouch.be"
                    };
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            setOutput(message + "\n\rAPI Account ID created.\n\rAPI Token created.");
                            setStep(props.t('CreatedApiToken'));
                            setProgress(80);
                            createLicences(db, message + "\n\rAPI Account ID created.\n\rAPI Token created.");
                        });
                    
                });
        } catch(e){
            console.error(e);
        }  
    }

    const createLicences = async(db, message) => {
        setStep(props.t('CreateSalesLicense'));

        let licenceD = new Date(new Date().getTime()+(parseInt(daysValid)*24*60*60*1000));
        licenceD = licenceD.getFullYear()+"-"+(licenceD.getMonth()+1).toString().padStart(2, '0')+"-"+licenceD.getDate().toString().padStart(2, '0');

        let url = SftGlobalData.baseURL_API + 'client&action=addLicence';
        let postData = {
            key: db.encrypted_key,
            lic_type: "1",
            is_trial: false,
            valid_until: licenceD,
            amount: 1
        };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('data', postData);
                console.log('response', response);
            }
            setProgress(85);
            setStep(props.t('CreateOfficeLicense'));
            setOutput(message + "\n\rSales Licence created.");
            url = SftGlobalData.baseURL_API + 'client&action=addLicence';
            postData = {
                key: db.encrypted_key,
                lic_type: "2",
                is_trial: false,
                valid_until: licenceD,
                amount: 1
            };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('data', postData);
                    console.log('response', response);
                }
                setOutput(message + "\n\rSales Licence created.\n\rOffice Licence created.");
                if(optionTraining){
                    createAndAddTraining(db, message + "\n\rSales Licence created.\n\rOffice Licence created.");
                } else {
                    addAndReturn(db);
                }
            });
        });
    }

    const createAndAddTraining = async (db, message) => {
        setStep(props.t('CreateTraining'));
        let url = SftGlobalData.baseURL_API + 'connection&action=createTestDb';
        let postData = {
                        override: "EG68$#sO!*eCXx",
                        key: db.encrypted_key
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setProgress(95);
            setStep(props.t('CreatedTraing'));
            setOutput(message + "\n\r" + response.data.message + "\n\r");
            addAndReturn(db);
            
        });
    }

    const addAndReturn = async(db) => {
        setStep(props.t('finish'));
        setProgress(100);
    }

    const optionCreateDbHandler = () => {
        if(!optionCreateDb) {
            setHost("sft5.softtouch.eu");
            let dbName = name.replaceAll(' ', '').replace(/[^a-z]/gi,'');
            setDatabase(dbName.length >= 20 ? dbName.substring(0,20) : dbName);
            setUser(generateDbCredential(14));
            setPassword(generateDbCredentialSpecialChars(16));
            setPort("3306");
        } else {
            setHost("sft5.softtouch.eu");
            setDatabase("");
            setUser("");
            setPassword("");
            setPort("3306");
        }
        setOptionCreateDb(!optionCreateDb);
        
    }

    const changeCustomerHandler = (val) => {
        let custDescr = customers.filter((e)=>e.klnr === val);
        custDescr = custDescr[0].firmanaam;
        setCustomer(val);
        setName(custDescr.replaceAll("\\", "").replaceAll("'", ""));
        if(optionCreateDb){
            setHost("sft5.softtouch.eu");
            let dbName = custDescr.replaceAll(' ', '').replace(/[^a-z]/gi,'').replaceAll("\\", "\\\\").replaceAll("'", "");
            setDatabase(dbName.length >= 20 ? dbName.substring(0,20) : dbName);
            setUser(generateDbCredential(14));
            setPassword(generateDbCredentialSpecialChars(16));
            setPort("3306");
        }
    }
    

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content ' + props.theme + ' container_content_' + props.theme : ' container_content _60perc ' + props.theme + ' container_content_' + props.theme} >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                {
                    // --------------------------------------------------------------------
                    // TITLE / HEADER
                    // --------------------------------------------------------------------
                }
                <div className={props.theme + " _100perc margin_bottom_15px"}>
                    <div className="container_list__title">
                        <Button disabled={disable} type="button" className="button_container__button align_left width_150px" onClick={()=>{navigate("/"+props.lang+"/environment");}}>&nbsp;{props.t('back')}&nbsp;</Button>
                        <h2 className={(props.theme==='dark') ? "dark container_list__title detail" : "light container_list__title detail"} type="text" id="list_name" name="listName">{props.t('new_connection')}</h2>
                    </div>
                </div>
                {
                    // --------------------------------------------------------------------
                    // Customer
                    // --------------------------------------------------------------------
                }
                <p className={"primary_border_bottom margin_top_10px margin_bottom_15px bold _100perc padding_bottom__5px"} style={{fontWeight: 1200}}>{props.t('customer')}</p>
                <Autocomplete
                    id="customer"
                    options={customers}
                    style={{ width: '100%' }}
                    getOptionLabel={(option) => `${option.klnr} - ${option.firmanaam}`}
                    value={customers.find(cust => cust.klnr === customer) || null}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            changeCustomerHandler(newValue.klnr);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.t('search')}
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            disabled={disable}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.klnr === value.klnr}
                    disabled={disable}
                />
                {
                    // --------------------------------------------------------------------
                    // Licence
                    // --------------------------------------------------------------------
                }
                <p className={"primary_border_bottom margin_top_10px margin_bottom_15px bold _100perc padding_bottom__5px"} style={{fontWeight: 1200}}>{props.t('licence')}</p>
                <div className={props.theme} style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                    <div className={props.theme + " flex_start_column _100perc"}>
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            id="name"
                            label={props.t('name')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={name}            
                            onChange={(event)=>{setName(event.target.value.replaceAll("\\", "").replaceAll("'", ""))}}
                            disabled={disable}
                            />
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            id="description"
                            label={props.t('description')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={description}            
                            onChange={(event)=>{setDescription(event.target.value)}}
                            disabled={disable}
                            />
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            id="daysValid"
                            label={props.t('days_valid')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={daysValid}            
                            onChange={(event)=>{setDaysValid(event.target.value)}}
                            disabled={disable}
                            />
                        <div className={props.theme } style={{width: "30%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                            <p>{props.t('monthly_rent')}?</p>
                            <Switch
                                disabled={disable}
                                checked={monthlyRent}
                                onChange={() => {setMonthlyRent(!monthlyRent);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                    {
                    // --------------------------------------------------------------------
                    // Options
                    // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " flex_start_column"} style={{marginLeft: 20, width: "30%" }}>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", marginTop:2}}>
                            <p style={{margin: 0}}>{props.t('training')}</p>
                            <Switch
                                disabled={disable}
                                checked={optionTraining}
                                onChange={() => {setOptionTraining(!optionTraining);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", marginTop:15}}>
                            <p style={{margin: 0}}>{props.t('SMS')}</p>
                            <Switch
                                disabled={disable}
                                checked={optionSMS}
                                onChange={() => {setOptionSMS(!optionSMS);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", marginTop:15}}>
                            <p style={{margin: 0}}>{props.t('API')}</p>
                            <Switch
                                disabled={disable}
                                checked={optionAPI}
                                onChange={() => {setOptionAPI(!optionAPI);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                </div>

                
                {
                    // --------------------------------------------------------------------
                    // Database
                    // --------------------------------------------------------------------
                }
                <p className={"primary_border_bottom margin_top_10px margin_bottom_15px bold _100perc padding_bottom__5px"} style={{fontWeight: 1200}}>Database</p>
                <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-start", alignItems: "center"}}>
                    <p>{props.t('create_database')}?</p>
                    <Switch
                        disabled={disable}
                        checked={optionCreateDb}
                        onChange={() => {optionCreateDbHandler();}}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <div className={props.theme} style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                    <div className={props.theme + " flex_start_column _100perc"}>
                        <Select            
                            id="host"
                            disabled={disable}
                            value={host}
                            //label="Host"
                            onChange={(e)=>{setHost(e.target.value);}}
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'} 
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }}}
                            >
                                <MenuItem value="test3.softtouch.eu">test3.softtouch.eu (78.46.86.113)</MenuItem>
                                <MenuItem value="sft2.softtouch.eu">sft2.softtouch.eu (178.208.36.152)</MenuItem>
                                <MenuItem value="sft4.softtouch.eu">sft4.softtouch.eu (178.208.36.147)</MenuItem>
                                <MenuItem value="sft5.softtouch.eu">sft5.softtouch.eu (178.208.36.150)</MenuItem>
                                <MenuItem value="sft6.softtouch.eu">sft6.softtouch.eu (178.208.36.153)</MenuItem>
                                
                        </Select>
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            id="database"
                            label={props.t('database')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={database}            
                            onChange={(event)=>{setDatabase(event.target.value)}}
                            disabled={disable}
                            />
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}

                            id="user"
                            label={props.t('user')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={user}            
                            onChange={(event)=>{setUser(event.target.value)}}
                            inputProps={{ maxLength: 16 }}
                            disabled={disable}
                            />
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            id="password"
                            label={props.t('password')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={password}            
                            onChange={(event)=>{setPassword(event.target.value)}}
                            inputProps={{ maxLength: 16 }}
                            disabled={disable}
                            />
                        <TextField
                            className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                            id="port"
                            label={props.t('port')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            value={port}            
                            onChange={(event)=>{setPort(event.target.value)}}
                            disabled={disable}
                            />
                    </div>
                    {
                    // --------------------------------------------------------------------
                    // Modules
                    // --------------------------------------------------------------------
                    }
                    <div style={{marginLeft:20 ,width: '30%', display: "flex", flexDirection: "column"}}>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", height: 60}}>
                            <p style={{margin: 0}}>{props.t('EDI')}</p>
                            <Switch
                                disabled={disable}
                                checked={modEdi}
                                onChange={()=>{setModEdi(!modEdi);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", height: 53}}>
                            <p style={{margin: 0}}>{props.t('web')}</p>
                            <Switch
                                disabled={disable}
                                checked={modWeb}
                                onChange={() => {setModWeb(!modWeb);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", height: 53}}>
                            <p style={{margin: 0}}>{props.t('marketing')}</p>
                            <Switch
                                disabled={disable}
                                checked={modMarketing}
                                onChange={() => {setModMarketing(!modMarketing);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className={props.theme } style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", height: 53}}>
                            <p style={{margin: 0}}>{props.t('B2B')}</p>
                            <Switch
                                disabled={disable}
                                checked={modB2B}
                                onChange={() => {setModB2B(!modB2B);}}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                </div>
                {
                    // --------------------------------------------------------------------
                    // PROGRESS / START CREATION
                    // --------------------------------------------------------------------
                }
                <p className={"primary_border_bottom margin_top_10px margin_bottom_15px bold _100perc padding_bottom__5px"} style={{fontWeight: 1200}}>{props.t('creation')}</p>
                <div className={props.theme + " _80perc flex_center_column"}>
                    <Button  className={"modal_button_container_no_margin__button margin_top_15px _60perc"} onClick={()=>{onStart();}} disabled={disable && !error}>{props.t('START')}</Button>
                    <LinearProgressWithLabel error={error} step={step} value={progress} />
                    {(disable || error) && <textarea className={(props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent_darker textarea'} id="output" name="output" value={output} style={{resize: 'vertical', minHeight: 300}} readOnly/>}
                    {step === props.t('finish') && <Button  className={"modal_button_container_no_margin__button margin_top_15px _60perc"} onClick={()=>{navigate("/"+props.lang+"/environment");}}>{props.t('finish')}</Button>}
                </div>
            </div>
        </div>
    );
}

export default CreateConnectionScreen;
