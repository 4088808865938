import { Button, FormControl, IconButton, InputAdornment, OutlinedInput, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Add, CheckCircle, Delete } from "@mui/icons-material";
import TaskTable from "../../components/logging/TaskTable";
import axios from "axios";
import SftGlobalData from "../../library/SftGlobalData";
import { useNavigate } from "react-router-dom";

const TodoListScreen = (props) =>  {
  const [tasks, setTasks] = useState([]);
  // User tasks
  const [userTasks, setUserTasks] = useState([]);
  const [userTasksLoading, setUserTasksLoading] = useState(true);

  let navigate = useNavigate();

  // Load tasks from localStorage on mount
  useEffect(() => {
    const storedTasks = JSON.parse(localStorage.getItem("tasks"));
    if (storedTasks) setTasks(storedTasks);
    loadTasks();
  }, []);

  // Save tasks to localStorage whenever they change
  useEffect(() => {
    if(tasks.length === 0) return;
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  // Add a new task
  const addTask = (text) => {
    const newTasks = text
      .split(";") // Split input by semicolon
      .map(task => task.trim()) // Trim spaces
      .filter(task => task.length > 0) // Remove empty tasks
    
    if (newTasks.length === 0) return; // Prevent adding if nothing valid
  
    setTasks([...tasks, ...newTasks.map(task => ({ text: task, done: false }))]);
  };
  

  // Toggle task completion
  const toggleTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].done = !updatedTasks[index].done;
    setTasks(updatedTasks);
  };

  // Delete a task (with confirmation)
  const deleteTask = (index) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      setTasks(tasks.filter((_, i) => i !== index));
    }
  };

  const loadTasks = () => {
    // USER Tasks
    try{
        let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
        let postData = {sft_id: 0, tasks: true, userId: props.userId, sft: props.loginObj?.sft_status}
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('resp', response);
                }
                setUserTasks(response.data);
                setUserTasksLoading(false);
            });
    } catch(e){
        console.error(e);
    }
};

  return (
    <div className="todo-container">
      <br/>
      <h2 className={props.theme} style={{marginTop: 20}}>My To-Do List</h2>
      <TaskInput onAdd={addTask} theme={props.theme} />
      <div className="columnContainerBetween">
          <ul>
            {tasks.map((task, index) => (
              <li key={index} className={task.done ? "done" : props.theme+"_accent"}>
                
                <span style={{width: "100%", marginRight: 5}} onClick={() => toggleTask(index)}>{task.text}</span>
                {/*<Button variant="contained" color="success" style={{marginRight: 5}} onClick={() => toggleTask(index)}>
                  <CheckCircle />
                </Button>*/}
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => deleteTask(index)}
                >
                  <Delete />
                </Button>
              </li>
            ))}
          </ul>
          <TaskTable login={props.loginObj} userSft={props.loginObj.sft_status} users={[]} loadTasks={()=>{loadTasks();}} userId={props.userId} initialGrid={{
          columns: {
            columnVisibilityModel: {
              log_date: false, 
              user_id: false, 
              log_tags: false, 
              log_time: false, 
              caller: false, 
              phone: false, 
              teamviewer_id: false, 
              teamviewer_pass: false, 
              log_solution: false, 
              cust_store: false
            },
          },
        }} data={userTasks} loading={userTasksLoading} t={props.t} theme={props.theme} screenWidth={props.screenWidth}/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <div
          style={{bottom: 75, position: "fixed", width: "19%", zIndex: 998, display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
            <Button style={{width: "49%"}} variant="contained" color="info" onClick={()=>{loadTasks();}}>Refresh</Button>
            <Button style={{width: "49%"}} variant="contained" color="info" onClick={()=>{navigate("/"+props.lang+"/logging/add");}}>Add task</Button>
          </div>
          
          <Button style={{bottom: 30, position: "fixed", width: "19%", zIndex: 998}} variant="contained" color="error" onClick={()=>{props.close();}}>Close</Button>
      </div>
      
      <style>{`
        .todo-container { text-align: center; font-family: Arial, sans-serif; margin-top: 20px; }
        ul { list-style-type: none; padding: 0; }
        li { display: flex; align-items: center; justify-content: space-between; padding: 10px; margin: 10px auto; width: 100%; border-radius: 5px; }
        .done span { text-decoration: line-through; color: gray; }
        .done { background-color: #5CB85C; }
      `}</style>
    </div>
  );
}

// Input component for adding tasks
function TaskInput({ onAdd, theme }) {
  const [text, setText] = useState("");

  const handleSubmit = () => {
    onAdd(text);
    setText(""); // Clear input after adding
  };

  return (
    <div className=" rowContainerBetween ">
        <FormControl sx={{ m: 1, width: '100%', margin: '0' }}>
            <OutlinedInput
                id="outlined-adornment"
                type={'text'}
                name="txt_text" 
                className={theme+"_accent"}
                value={text}
                onChange={(event)=>{setText(event.target.value)}}
                placeholder={"Add item"}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="add text"
                            onClick={()=>handleSubmit()}
                            edge="end"
                            >
                            <Add />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
  </div>
  );
}

export default TodoListScreen;