import React, { useState, useEffect } from 'react';
import { TailSpin } from  'react-loader-spinner';
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import ModuleCard from '../components/ModuleCard';
import SwitchSetting from '../components/mobile/SwitchSetting';
import OptionSetting from '../components/mobile/OptionSetting';
import InputSetting from '../components/mobile/InputSetting';
import { useParams, Link } from 'react-router-dom';
import Colors from '../library/Colors';
import Button from '@mui/material/Button';


const MobileModuleSettingsScreen = (props) => {

    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);
    let params = useParams();


    useEffect(() => {
        if(props.api !== ""){
            reload();
        }
    },[props.api, params?.groupId, params.module]);

    const reload = () => {
        setLoading(true);
        let moduleName = params.module + "";
        moduleName = moduleName.toLowerCase();
        let url = SftGlobalData.baseURL_API + 'mobileSettings&action=getAppSettings';
        let postData = {
            key: props.api,
            categoryType: "settings",
            category: moduleName
        };
        if(typeof params?.groupId !== "undefined" && params?.groupId !== '0000'){
            postData.groupId = params?.groupId;
        }
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setSettings(response.data.settings);
            setLoading(false);
        });
    }

    const resetHandler = async () => {
        let moduleName = params.module + "";
        moduleName = moduleName.toLowerCase();
        
        let url = SftGlobalData.baseURL_API + 'mobileSettings&action=resetAppSettings';
        let postData = {
            key: props.api,
            groupId: params?.groupId,
            categoryType: "settings",
            category: moduleName
        };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            reload();
        });
    }

    const settingsMapper = settings.map(setting => {
        let settName = setting.title_nl;
        let settDescription = setting.description_nl;
        switch(props.lang){
            case 'nl':
                settName = setting.title_nl;
                settDescription = setting.description_nl;
                break;
            case 'fr':
                settName = setting.title_fr;
                settDescription = setting.description_fr;
                break;
            case 'en':
                settName = setting.title_en;
                settDescription = setting.description_en;
                break;
            default: 
                settName = setting.title_nl;
                settDescription = setting.description_nl;
                break;
        }
        
        switch(setting.setting){
            case 'settPickingMainfield':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="STOCKPOS" checked={setting.value==="STOCKPOS"}>STOCKPOS</option>
                        <option value="DETAIL_1" checked={setting.value==="DETAIL_1"}>DETAIL_1</option>
                        <option value="DETAIL_2" checked={setting.value==="DETAIL_2"}>DETAIL_2</option>
                        <option value="DETAIL_3" checked={setting.value==="DETAIL_3"}>DETAIL_3</option>
                        <option value="DETAIL_4" checked={setting.value==="DETAIL_4"}>DETAIL_4</option>
                        <option value="BARBODYCOLOR"checked={setting.value==="BARBODYCOLOR"}>BARBODYCOLOR</option>
                        <option value="BRAND" checked={setting.value==="BRAND"}>BRAND</option>
                    </OptionSetting>
                );
            case 'settPickingSortfield':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="STOCKPOS" checked={setting.value==="STOCKPOS"}>STOCKPOS</option>
                        <option value="DETAIL_1" checked={setting.value==="DETAIL_1"}>DETAIL_1</option>
                        <option value="DETAIL_2" checked={setting.value==="DETAIL_2"}>DETAIL_2</option>
                        <option value="DETAIL_3" checked={setting.value==="DETAIL_3"}>DETAIL_3</option>
                        <option value="DETAIL_4" checked={setting.value==="DETAIL_4"}>DETAIL_4</option>
                        <option value="BARBODYCOLOR"checked={setting.value==="BARBODYCOLOR"}>BARBODYCOLOR</option>
                        <option value="BRAND" checked={setting.value==="BRAND"}>BRAND</option>
                        <option value="CATEGORY_1" checked={setting.value==="CATEGORY_1"}>CATEGORY_1</option>
                        <option value="CATEGORY_2" checked={setting.value==="CATEGORY_2"}>CATEGORY_2</option>
                        <option value="CATEGORY_3" checked={setting.value==="CATEGORY_3"}>CATEGORY_3</option>
                        <option value="CATEGORY_4" checked={setting.value==="CATEGORY_4"}>CATEGORY_4</option>
                        <option value="CATEGORY_5" checked={setting.value==="CATEGORY_5"}>CATEGORY_5</option>
                    </OptionSetting>
                );
            case 'settTransferRequestRule':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="ALWAYS" checked={setting.value==="ALWAYS"}>{props.t('always')}</option>
                        <option value="ASK" checked={setting.value==="ASK"}>{props.t('ask')}</option>
                        <option value="NEVER" checked={setting.value==="NEVER"}>{props.t('never')}</option>
                    </OptionSetting>
                );
            case 'settPacklistMode':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="SCAN" checked={setting.value==="SCAN"}>{props.t('always')} {props.t('scan')}</option>
                        <option value="ASK" checked={setting.value==="ASK"}>{props.t('ask')}</option>
                        <option value="PROCESS" checked={setting.value==="PROCESS"}>{props.t('always')} {props.t('process')}</option>
                    </OptionSetting>
                );
            case 'settStockPosUpdateRule':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="ID" checked={setting.value==="ID"}>ID</option>
                        <option value="Variant" checked={setting.value==="Variant"}>Variant</option>
                        <option value="Barcode" checked={setting.value==="Barcode"}>Barcode</option>
                    </OptionSetting>
                );
            case 'settConsignmentTypeRule':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="RETURNED" checked={setting.value==="RETURNED"}>SCAN RETURNED ARTICLES</option>
                        <option value="ASK" checked={setting.value==="ASK"}>{props.t('ask')}</option>
                        <option value="SOLD" checked={setting.value==="SOLD"}>SCAN SOLD ARTICLE (LABELS)</option>
                    </OptionSetting>
                );
            case 'settConsignmentProcessRule':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="CORRECTION" checked={setting.value==="CORRECTION"}>STOCK CORRECTION</option>
                        <option value="ASK" checked={setting.value==="ASK"}>{props.t('ask')}</option>
                        <option value="SALE" checked={setting.value==="SALE"}>MANUAL SALE</option>
                    </OptionSetting>
                );
            case 'elementsColor':
                let hexColor = "#"+setting.value;
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={hexColor} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} colorpick={true} theme={props.theme}/>);
            case 'elementsTextColor':
                let hexColorText = "#"+setting.value;
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={hexColorText} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting}  colorpick={true} theme={props.theme}/>);
            case 'settLabelTitleOne':
            case 'settLabelTitleTwo':
            case 'settLabelFieldOne':
            case 'settLabelFieldTwo':
            case 'settLabelFieldThree':
            case 'settLabelFieldFour':
            case 'settLabelFieldFive':
            case 'settLabelFieldSix':
            case 'settLabelFieldSeven':
            case 'settLabelFieldEight':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="-" checked={setting.value==="-"}>-</option>
                        <option value="brand" checked={setting.value==="brand"}>{props.t('brand')}</option>
                        <option value="category1" checked={setting.value==="category1"}>{props.t('category')} 1</option>
                        <option value="category2" checked={setting.value==="category2"}>{props.t('category')} 2</option>
                        <option value="category3" checked={setting.value==="category3"}>{props.t('category')} 3</option>
                        <option value="category4" checked={setting.value==="category4"}>{props.t('category')} 4</option>
                        <option value="category5" checked={setting.value==="category5"}>{props.t('category')} 5</option>
                        <option value="category6" checked={setting.value==="category6"}>{props.t('category')} 6</option>
                        <option value="category7" checked={setting.value==="category7"}>{props.t('category')} 7</option>
                        <option value="color" checked={setting.value==="color"}>{props.t('color')}</option>
                        <option value="colorbrand" checked={setting.value==="colorbrand"}>{props.t('colorbrand')}</option>
                        <option value="detail1" checked={setting.value==="detail1"}>{props.t('detail')} 1</option>
                        <option value="detail2" checked={setting.value==="detail2"}>{props.t('detail')} 2</option>
                        <option value="detail3" checked={setting.value==="detail3"}>{props.t('detail')} 3</option>
                        <option value="detail4" checked={setting.value==="detail4"}>{props.t('detail')} 4</option>
                        <option value="detail5" checked={setting.value==="detail5"}>{props.t('detail')} 5</option>
                        <option value="description" checked={setting.value==="description"}>{props.t('description')}</option>
                        <option value="description2" checked={setting.value==="description2"}>{props.t('description')} 2</option>
                        <option value="description3" checked={setting.value==="description3"}>{props.t('description')} 3</option>
                        <option value="description4" checked={setting.value==="description4"}>{props.t('description')} 4</option>
                        <option value="description5" checked={setting.value==="description5"}>{props.t('description')} 5</option>
                    </OptionSetting>
                );
            case 'settLabelTemplate':
                return (
                    <OptionSetting key={setting.setting} title={settName} description={settDescription} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} defaultValue={setting.value} category={setting.category} setting={setting.setting} theme={props.theme}>
                        <option value="Template 1" checked={setting.value==="Template 1"}>{props.t('template')} 1</option>
                        <option value="Template 2" checked={setting.value==="Template 2"}>{props.t('template')} 2</option>
                    </OptionSetting>
                );
            case 'settSalesDefaultSalesLength':
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={setting.value} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} colorpick={false} theme={props.theme}/>);
            case 'settSalesDefaultValue':
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={setting.value} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} colorpick={false} theme={props.theme}/>);
            case 'settGeneralLine1':
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={setting.value} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} colorpick={false} theme={props.theme}/>);
            case 'settGeneralLine2':
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={setting.value} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} colorpick={false} theme={props.theme}/>);
            case 'settGeneralLine3':
                return (<InputSetting key={setting.setting} title={settName} description={settDescription} defaultValue={setting.value} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} colorpick={false} theme={props.theme}/>);

                
            default:
                break;
        }
        if(setting.category === "module"){
            return (<div className='card_container_center'><ModuleCard key={setting.setting} title={settName} setting={setting.setting.slice(3)} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} checked={setting.value} noClick={true} theme={props.theme}/></div>);
        }
        if(setting.value === "0" || setting.value === "1"){
          return (<SwitchSetting key={setting.setting} title={settName} description={settDescription} checked={setting.value} api={props.api} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)} category={setting.category} setting={setting.setting} theme={props.theme}/>);
        }else{
          return "";
        }
        
    });

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <div className="container_list__title">
                <Link to={"/"+props.lang+"/mobile"}>
                    <Button type="button" className="button_container__button align_left width_150px">&nbsp;{props.t('back')}&nbsp;</Button>
                </Link>
                <h2 className={props.theme + " container_list__title detail"}>{params.module} {props.groupName !== "" && typeof props.groupName !== "undefined" ? " - " + props.groupName : ""}</h2>
                <div className='button_container__button align_right width_150px'></div>
            </div>

            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && <fieldset className={(props.theme==='light') ? 'light container_list' : 'dark  container_list'}>              
                {params.module === 'General' ? <div className='card_container_center'> <ModuleCard key={params.module} title={params.module} api={props.api} theme={props.theme} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)}  noClick={true}/></div> : ''}  
                {/*params.module === 'Label' ? <div className='card_container_center'> <ModuleCard key={params.module} setting={"Label"} title={params.module} api={props.api} theme={props.theme} groupId={(typeof params?.groupId === "undefined" ? "0000" : params?.groupId)}  noClick={true}/></div> : ''*/}               
                <div className={"container-group"}>
                    {settingsMapper}
                </div>
            
                {(typeof params?.groupId === "undefined" || params?.groupId === '0000') ? '' : <div className=''><Button onClick={()=>{resetHandler();}}>{props.t('use_general')}</Button></div>}
            </fieldset>}
        </div>  
    );
  }


export default MobileModuleSettingsScreen;
