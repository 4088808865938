import React, { useState } from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';

import Label_template_1 from '../../assets/mobile-icons/template_label_1.png';
import Label_template_2 from '../../assets/mobile-icons/template_label_2.png';

const OptionSetting = (props) => {
    const [value, setValue] = useState(props.defaultValue);

    const submitHandler = async (value) => {
        let url = SftGlobalData.baseURL_API + 'mobileSettings&action=editSettings';  // first, insert new modules if need be.
        let postData = {
            key: props.api,
            category: props.category,
            setting: props.setting,
            value: value ? "1" : "0"
        };
        if (props.groupId !== '0000') {
            postData.groupId = props.groupId;
        }
        axios.post(url, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(response => {
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('response', response);
            }
        });
    }

    const change = (event) => {
        setValue(event.target.value);
        submitHandler(event.target.value);
    }

    return (        
            <div>
                
                <div style={{height: 68}}>
                    <h2 className='_80perc align_left'>{props.title}</h2>
                    <select className={props.theme+'_accent_darker _20perc ' + (props.description !== "-" ?  ' margin_bottom_20px ' : 'margin_bottom_10px') } onChange={change} value={value}>
                        {props.children}
                    </select>       
                </div>  
                {props.description !== "-" && <div className='_100perc'>    
                    <p>{props.description}</p>
                </div> }
                {props.description !== "-" && <hr className={'margin_top_40px' + (props.theme==='dark') ? 'light_text' : 'dark_text'}/>}
                {props.setting === "settLabelTemplate" && <div className=' _100perc flex_center_column'>
                    <img style={{margin: 'auto'}} src={value==="Template 2" ? Label_template_2 : Label_template_1} alt={props.title} />
                </div>}
            </div>
        
    );
}

export default OptionSetting;