import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SftGlobalData from '../../library/SftGlobalData';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import './excel.css';
import FloatingBackButton from '../../components/FloatingBackButton';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import Colors from '../../library/Colors';
import moment from 'moment';



const ExcelImportScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [nameFr, setNameFr] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionFr, setDescriptionFr] = useState("");
    const [impact, setImpact] = useState("");
    const [example, setExample] = useState("");
    const [exampleCols, setExampleCols] = useState([]);
    const [exampleRows, setExampleRows] = useState([]);
    const [requiredFields, setRequiredFields] = useState("");
    const [table, setTable] = useState("");
    const [index, setIndex] = useState("");
    const [filter, setFilter] = useState(false);
    const [queries, setQueries] = useState("");
    const [validationQueries, setValidationQueries] = useState("");

    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState([]);
    const [fileName, setFileName] = useState("");

    const [validation, setValidation] = useState("");
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState("");
    const [resultRows, setResultRows] = useState(0);
    const params = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        //console.log("selectedDb", props.db?.user_id);
        let url = SftGlobalData.baseURL_API + 'excel&action=fetchOne';
        axios.post(url, {key: props.api, mod: params.mod, validate: true, sft_status: props.login?.sft_status}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    if(typeof response.data.mod_name !== "undefined") setName(response.data.mod_name);
                    if(typeof response.data.mod_name_fr !== "undefined") setNameFr(response.data.mod_name_fr);
                    if(typeof response.data.required_fields !== "undefined") setRequiredFields(response.data.required_fields);
                    if(typeof response.data.mod_impact !== "undefined") setImpact(response.data.mod_impact);
                    if(typeof response.data.config?.description !== "undefined") setDescription(response.data.config?.description);
                    if(typeof response.data.config?.description_fr !== "undefined") setDescriptionFr(response.data.config?.description_fr);
                    if(typeof response.data.config?.index !== "undefined") setIndex(response.data.config?.index);
                    if(typeof response.data.config?.queries !== "undefined") setQueries((response.data.config?.queries?.replaceAll("\\\"", "'")?.replace(/;/g, ';\n\n')+"").replace("undefined", ""));
                    if(typeof response.data.config?.validation_queries !== "undefined") setValidationQueries((response.data.config?.validation_queries?.replaceAll("\\\"", "'")?.replace(/;/g, ';\n\n')+"").replace("undefined", ""));
                    if(typeof response.data.config?.table !== "undefined") setTable(response.data.config?.table);
                    if(typeof response.data.config?.allow_filter !== "undefined") setFilter(response.data.config?.allow_filter==true);
                    if(typeof response.data.example !== "undefined"){
                        setExample(response.data.example);
                        let base64String = response.data.example;
                        try{
                            if(!isBase64Image(response.data.example)){
                                // Extract MIME type
                                const mimeType = base64String.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,/)[1];

                                // Convert base64 to binary
                                const byteCharacters = atob(base64String.replace(/^data:.*;base64,/, ''));
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);

                                // Create blob
                                const blob = new Blob([byteArray], { type: mimeType });
                                try{renderExample(blob);}catch(e){console.e(e);}
                            }
                        }catch(e){
                            console.error(e);
                        }
                    } 
                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                setLoading(false);
            });
        props.changeScreen('Licence');
    },[props.api]);

    const renderExample = (fileObj) => {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if(err){
              console.log(err);            
            }
            else{
              if(SftGlobalData.debug){
                  console.log("DATA", resp);
              }
              let columns = resp.cols;
              columns.unshift({name: '', key: -1});
              for (let index = 0; index < columns.length; index++) {
                  const element = columns[index];
                  columns[index] = {name: element.name, key: (element.key+1)};
                  
              }
              setExampleCols(columns);
              setExampleRows(resp.rows);
            }
        }); 
    }

    const fileHandler = (event) => {
        if(event.target.files.length){
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;
            setFileName(fileName);
            renderFile(fileObj);
        }
    }

    const renderFile = (fileObj) => {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
            if(SftGlobalData.debug){
                console.log("DATA", resp);
            }
            setDataLoaded(true);
            let columns = resp.cols;
            columns.unshift({name: '', key: -1});
            for (let index = 0; index < columns.length; index++) {
                const element = columns[index];
                columns[index] = {name: element.name, key: (element.key+1)};
            }
            setCols(columns);
            setRows(resp.rows);
          }
        }); 
    }
    
    const importExcel = () => {
        // END 
        if(SftGlobalData.debug)
            console.log("rows", rows);
        if(rows.length > 0){
            setLoading(true);
            let filterUsed = false;
            const required_fields = requiredFields;

            const requiredFieldsArray = required_fields.toLowerCase().split(',');
            requiredFieldsArray.forEach((field, index) => {
                requiredFieldsArray[index] = field.trim();
            });
            const fieldPositions = {};
            rows[0].forEach((field, index) => {
                //if(SftGlobalData.debug)
                //    console.log("field", field);
                try{
                    const lowercaseField = field?.toLowerCase()?.trim();
                    if (requiredFieldsArray.includes(lowercaseField)) {
                        fieldPositions[lowercaseField] = index;
                    } else {
                        if(filter && (lowercaseField === "store")){
                            fieldPositions[lowercaseField] = index;
                            filterUsed = true;
                        }
                    }
                }catch(e){
                    props.notify(`Something might have gone wrong while processing the headers of the file... If the page keeps loading for longer than 5 minutes, please retry after validating the headers in the file.`, 10000, "bottom-center", props.theme, 6879); 
                    //console.error(e);
                }
            });

            if(SftGlobalData.debug)
                console.log("fields", fieldPositions);

            // Check if all required fields are found
            const missingFields = requiredFieldsArray.filter(field => !fieldPositions.hasOwnProperty(field));
            if (missingFields.length > 0) {
                props.notify(`Missing required fields: ${missingFields.join(', ')}`, 2000, "bottom-center", props.theme, 33548); 
                setError(`Missing required fields: ${missingFields.join(', ')}`);
                setLoading(false);
            } else {
                setError("");
                const jsonData = rows.slice(1).map(row => {
                    const rowData = {};
                    Object.keys(fieldPositions).forEach(fieldName => {
                        let value = row[fieldPositions[fieldName]];
                        if (fieldName.includes('validuntil') || fieldName.includes('validfrom') || fieldName.includes('date') || fieldName.includes('solden_sta') || fieldName.includes('solden_end')) {
                            let parsedDate;
                            const numericValue = parseInt(value); // Parse the numeric value
                            if (!isNaN(numericValue)) {
                                if(SftGlobalData.debug)
                                    console.log(`Raw Excel Value: ${value}, Parsed Numeric Value: ${numericValue}`);
                                const excelEpoch = 25567; // Excel epoch correction
                                const millisecondsPerDay = 86400 * 1000; // Convert days to milliseconds
                            
                                // Ensure no time component from Excel
                                const numericValueFixed = Math.floor(numericValue);

                                const utcTimestamp = (numericValueFixed - 25567 - 2) * 86400 * 1000;
                                if(SftGlobalData.debug)
                                    console.log(`Computed UTC Timestamp: ${utcTimestamp}, Date Check: ${new Date(utcTimestamp).toISOString()}`);

                            
                                // **Subtract 2 days to fix Excel's 1900 leap year bug and adjust for the date offset**
                                const correctedDays = numericValueFixed - excelEpoch - 2;
                            
                                // Convert to moment and ensure it's at the start of the day
                                parsedDate = moment.utc(correctedDays * millisecondsPerDay).startOf('day');
                            } else {
                                // Try parsing with European format "DD/MM/YYYY"
                                parsedDate = moment.utc(value, ['DD/MM/YYYY', 'YYYY-MM-DD'], true); // Set strict parsing
                                if (!parsedDate.isValid()) {
                                    // If parsing with European format fails, try parsing with "YYYY-MM-DD"
                                    parsedDate = moment.utc(value, 'YYYY-MM-DD', true); // Set strict parsing
                                }
                            }
                            if (parsedDate.isValid()) {
                                // Format the date as "YYYY-MM-DD"
                                const formattedDate = parsedDate.format('YYYY-MM-DD');
                                rowData[fieldName] = formattedDate;
                            } else {
                                rowData[fieldName] = ''; // If parsing fails, set empty string
                            }
                        } else {
                            rowData[fieldName] = value;
                        }
                        /*if (fieldName.includes('validuntil') || fieldName.includes('validfrom') || fieldName.includes('date')) {
                            // Parse date and format it as "YYYY-MM-DD"
                            const parsedDate = new Date(value);
                            if (!isNaN(parsedDate.getTime())) {
                                const formattedDate = parsedDate.toISOString().slice(0, 10);
                                rowData[fieldName] = formattedDate;
                            } else {
                                rowData[fieldName] = value; // If parsing fails, keep the original value
                            }
                        } else {
                            rowData[fieldName] = value;
                        }*/
                    });
                    return rowData;
                });

                const allKeys = new Set(jsonData.flatMap(obj => Object.keys(obj)));

                const normalizedArray = jsonData.map(obj =>
                  Array.from(allKeys).reduce((acc, key) => {
                    acc[key] = obj[key] !== undefined ? obj[key] : null; // Default to null if undefined
                    return acc;
                  }, {})
                );

                if(SftGlobalData.debug)
                    console.log("IMPORT", normalizedArray);

                setValidation("Validating data...");
                let url = SftGlobalData.baseURL_API + 'excel&action=validate';
                let postData = {key: props.api, mod: params.mod, import: normalizedArray, user_id: props.db?.user_id, filter: filterUsed};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }, timeout: 600000}) 
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('response', response);
                            console.log("postData", postData);
                        }
                        if(response.data.success){
                            setResultRows(response.data.rows);
                            setValidation("Validation completed, executing import...");
                            let url2 = SftGlobalData.baseURL_API + 'excel&action=import';
                            let postData2 = {key: props.api, mod: params.mod, user_id: props.db?.user_id, filter: filterUsed};
                            axios.post(url2, postData2, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }, timeout: 600000}) 
                                .then(response2 => {
                                    if(SftGlobalData.debug){
                                        console.log('url', url2);
                                        console.log('response', response2);
                                    }
                                    setError(response2.data.error);
                                    setFinished(true);
                                    setLoading(false);
                                });
                        } else {
                            setValidation("Validation failed.");
                            if(props.lang === "fr" && response.data.validation_fr !== ""){
                                setError(response.data.validation_fr);
                            } else if(props.lang === "nl" && response.data.validation_nl !== ""){
                                setError(response.data.validation_nl);
                            } else {
                                setError(response.data.error);
                            }
                            setLoading(false);
                            setFinished(false);
                        }
                        
                        
                    });
            }
        } else {
            props.notify("No file selected!", 2000, "bottom-center", props.theme, 8974); 
        }
        
    }

    const isBase64Image = (base64String) => {
        // Regular expression to check if the base64 string starts with the data URL scheme for an image
        const regex = /^data:image\/(jpeg|jpg|png|gif|bmp|svg\+xml);base64,/i;
        return regex.test(base64String);
    }

    const restart = () => {
        setDataLoaded(false); setRows([]); setFileName(""); setCols([]); setFinished(false); setError(""); setResultRows(0); setValidation(""); setLoading(false);
    }

    const replaceCodeTags = (text) => {
        // Define a regular expression to match <code> tags and their contents
        const regex = /<code>(.*?)<\/code>/g;

        // Replace <code> tags with desired content
        return text.replace(regex, '<code class="code_excel">$1</code>');
    };

    function formatStringWithCodeTags(str) {
        // Split the string into an array of values
        const values = str.split(',');
        
        // Wrap each value with <code className='code_excel'></code> and include spaces after commas
        const formattedValues = values.map((value, index) => (
          <React.Fragment key={index}>
            <code className='code_excel'>{value.trim()}</code>
            {index < values.length - 1 && ", "} {/* Add comma and space if it's not the last value */}
          </React.Fragment>
        ));
        
        return formattedValues;
      }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}} className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <FloatingBackButton disabled={loading} onClick={()=>{navigate("/"+props.lang+"/excel");}} down={true} />
            <div className={"container_list__title " + props.theme}><h2>Excel import</h2></div>
            {!finished && <div>
                {loading && <div style={{display:"flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "center", alignItems: "center", marginTop: 5}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                    <center><p>{validation}</p></center>
                </div>}

                {(!loading && name === "") && <div className='_100perc'>
                    <center><p className='error_text' >Module is not active for client.</p></center>
                </div>}

                {(!loading && name !== "") && <div className='_100perc'>
                    <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.lang === "fr" ? nameFr : name}</h3>
                    <blockquote>
                        <small dangerouslySetInnerHTML={{ __html: replaceCodeTags((props.lang === "fr" ? descriptionFr : description)) }} />
                    </blockquote>
                    <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select a file</h4>
                    <blockquote>
                        <small>Supported Formats: <code className='code_excel'>.xls</code> <code className='code_excel'>.xlsx</code> <code className='code_excel'>.csv[,]</code></small>
    					<small>Recommended Max Rows: <code className='code_excel'>10.000-15.000</code></small>
    					<small>Recommended Max Size: <code className='code_excel'>3-5 MB</code></small>
    					<small>Format date as: <code className='code_excel'>JJJJ-MM-DD</code><br/></small>
                        <small>Required fields: {formatStringWithCodeTags(requiredFields)}<br/></small>
    					<small>Example Layout:<br/><br/>
    					{(isBase64Image(example) && example !== "") && <img src={example} style={{maxWidth: "100%"}} />}
                        {(!isBase64Image(example) && example !== "") && <OutTable data={exampleRows} columns={exampleCols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />}
    					</small>
    				</blockquote>
                    
                    <div className='rowContainerBetween ' style={{marginLeft: 40, marginRight: 40, display: 'flex', flex: 1}}>
                        <Button style={{height: 45, padding: 10, marginTop: 8, marginRight: 10}} onClick={()=>{importExcel()}}  className={"modal_button_container_no_margin__button _25perc"} >START</Button>
                        <input style={{height: 45, padding: 10, width: "90%"}}  type="file" hidden onChange={fileHandler} onClick={(event)=> { event.target.value = null }} accept=".xlsx, .xls, .csv" />
                    </div>
                    {error !== "" && <p className='error_text' style={{marginLeft: 40, marginRight: 40}}>{(typeof error !== "undefined" && error !== "") && <span dangerouslySetInnerHTML={{ __html: error }}></span>}</p>}
                </div>}
            </div>}

            {finished && <div>
                <center>
                    {error === "" && <p>Import complete, file contained {resultRows+""} rows.</p>}
                    {error !== "" && <p><b className='error_text'>Import failed.</b> <br/> {(typeof error !== "undefined" && error !== "") && <span dangerouslySetInnerHTML={{ __html: error }}></span>}</p>}
                    <Button style={{height: 45, padding: 10, marginTop: 8}} onClick={()=>{restart()}}  className={"modal_button_container_no_margin__button _25perc"} >Import more</Button>
                </center>
            </div>}

            
            
        </div>
    );
}

export default ExcelImportScreen;
