import React, { useState } from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';

const SwitchSetting = (props) => {
    const [checkedState, setCheckedState] = useState(parseInt(props.checked) === 1 ? true : false);
    if(SftGlobalData.debug){console.log("SWITCH", props)};

    const submitHandler = async (value) => {
      let url = SftGlobalData.baseURL_API + 'mobileSettings&action=editSettings';  // first, insert new modules if need be.
      let postData = {
          key: props.api,
          category: props.category,
          setting: props.setting,
          value: value ? "1" : "0"
      };
      if(props.groupId !== '0000'){
        postData.groupId = props.groupId;
      }
      axios.post(url, postData, {
          headers : {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
          }
      }).then(response => {
          if(SftGlobalData.debug){
              console.log('url', url);
              console.log('response', response);
          }
      });
    }

    return (
          <div className="setting-container">
            <label className="switch" style={{float: "right"}}>
              <input type="checkbox" checked={checkedState} onChange={() => {submitHandler(!checkedState); setCheckedState(!checkedState)}}/>
              <span className={"slider slider_" + props.theme + " round"}></span>
            </label>
            <div>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
            <hr className={'margin_top_40px' + (props.theme==='dark') ? 'light_text' : 'dark_text'}/>
          </div>
    );
}

export default SwitchSetting;
